'use strict';

angular.module('poi360.components.standard-video-player.standard-video-player-directive', [])

.directive('standardVideoPlayer', [function(){
	return {
		scope: {
			config: "=",
			autoPlay: "@"
		},
		templateUrl: "scripts/app/components/standard-video-player/standard-video-player.html",
		link: function (scope, elem, attr) {
			scope.autoPlay = scope.autoPlay == 'true' ? true : false;
		}
	};
}]);