'use strict';

// Declare app level module which depends on views, and components
angular.module('poi360', [
  "ui.router",
  "ngSanitize",
  "ngAnimate",
  "updateMeta",
  "oc.lazyLoad",
  "com.2fdevs.videogular",
  "com.2fdevs.videogular.plugins.controls",
  "com.2fdevs.videogular.plugins.overlayplay",
  "com.2fdevs.videogular.plugins.poster",
  "com.2fdevs.videogular.plugins.buffering",
  'th.co.softever.videogular.plugins.quality',
  'poi360.animations',
  'poi360.factory',
  'poi360.views.homepage',
  'poi360.views.poi-the-heartbeat-of-a-nation',
  'poi360.views.poi-360-experience',
  'poi360.views.explore-aotearoa',
  'poi360.views.interviews-profiles',
  'poi360.views.interview-profile',
  'poi360.views.learn-how-to-poi',
  'poi360.views.poi-flashcards',
  'poi360.views.around-the-world',
  'poi360.views.about',
  'poi360.views.contact',
  'poi360.views.press',
  'poi360.views.legal',
  'poi360.views.poi-performance',
  'poi360.views.poi-hopes-dreams',
  'poi360.components.header',
  'poi360.components.sharebar',
  'poi360.components.footer',
  'poi360.components.owlcarousel',
  'poi360.components.vg-poi-marker-overlay',
  'poi360.components.standard-video-player',
  'poi360.components.page-background',
  'poi360.components.explore-map',
  'poi360.components.info-box',
  'poi360.components.loading-screen',
  'poi360.components.meta-tags',
  'poi360.components.helpers.sticky-scroll',
  'poi360.components.interviews-profiles-previews.interviews-profiles-previews',
  'poi360.components.video-gallery.video-gallery',
  'poi360.components.volume-bar.volume-bar',
  'poi360.components.sound-effect.sound-effect',
  'poi360.components.helpers'
])

.factory('Auth', ['$http', function($http) { 
	
    //return { isLoggedIn: true};

   return {
       getSource: function(callback) {
					// Simple GET request example:
					$http({
					  method: 'GET',
					  url: 'geolocation.php'
					}).then(function successCallback(response) {
					    // this callback will be called asynchronously
					    // when the response is available
					    // console.log('successCallback');
					    //console.log(response);
							//$rootScope.showItem = response.data;
					    //console.log('response: '+$rootScope.showItem);
					    //return $rootScope.showItem;
					    //return { isLoggedIn: true};
					    callback(response);
					  }, function errorCallback(response) {
					    // called asynchronously if an error occurs
					    // or server returns response with an error status.
					    // console.log('errorCallback');
					    //return false;
					  });
       } // getSource
   } // return

}])

.controller('LoginCtrl',['$scope', 'Auth', function($scope, Auth) 
{ 
  $scope.auth = Auth;
}])

/*
.run(['$window', '$rootScope', '$http', 'Auth', '$state', '$location', function($window, $rootScope, $http, Auth, $state, $location) {
 
 	console.log('run');
 
	$rootScope.setVars = Auth.getSource(function(response) {
		console.log('setVars::data ', response.data);
		$rootScope.showItem = response.data;
		Auth.isLoggedIn = response.data;
		$rootScope.location();
	}); 
		  	  
  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
    
		$rootScope.location = function() {
				console.log('Auth.isLoggedIn: ', Auth.isLoggedIn);
		    
		    var shouldLogin =  $location.path() == '/poi-hopes-dreams' 
		                 			 && !Auth.isLoggedIn ;
		    
		    // NOT authenticated - wants any private stuff
		    if(shouldLogin)
		    {
		      //event.preventDefault();
		      $location.path('/');
		      return;
		    }
	    }
  });
  
}])
*/

.config(['$urlRouterProvider', '$compileProvider', '$locationProvider', function($urlRouterProvider, $compileProvider, $locationProvider) {
  $urlRouterProvider.otherwise("/");
  $compileProvider.debugInfoEnabled(false);
  $locationProvider.hashPrefix('!');
  $locationProvider.html5Mode(false);
}])

.controller('poi360Controller', ['$scope', '$rootScope', 'progressBar', '$animate', 'appHeaderFactory', 'pageFactory', 'animationSettings', '$http', 'Auth', '$location', function($scope, $rootScope, progressBar, $animate, appHeaderFactory, pageFactory, animationSettings, $http, Auth, $location) {
	
  //init 
  appHeaderFactory.showHeader(false);

  progressBar.reset();

  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options){
    //start page transition

    if(toState.loadItems > 0){
      progressBar.init(toState.loadItems);
    }
    
    pageFactory.setCurrentPage(toState.name, toParams);
    var currentPage = pageFactory.getCurrentPage();

    $('#ga-tracking').html("<script>ga('set', 'page', '"+currentPage.url+"'); ga('send', 'pageview');</script>");
    
		$rootScope.location = function() {
				// console.log('Auth.isLoggedIn: ', Auth.isLoggedIn);
		    
		    var shouldLogin =  $location.path() == '/poi-hopes-dreams' 
		                 			 && !Auth.isLoggedIn ;
		    
		    // NOT authenticated - wants any private stuff
		    if(shouldLogin)
		    {
		      //event.preventDefault();
		      $location.path('/');
		      return;
		    }
	    }
	        
 	// console.log('run A');
 
	$rootScope.setVars = Auth.getSource(function(response) {
		// console.log('setVars::data ', response.data);
		$rootScope.showItem = response.data;
		Auth.isLoggedIn = response.data;
		$rootScope.location();
	});    
    
    
  });

  $rootScope.$on('$viewContentLoading', function(event, viewConfig){ 
		//console.log('viewContentLoading');
		//$rootScope.showItem = $rootScope.getShowItem();
  });

  $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){
    document.body.scrollTop = document.documentElement.scrollTop = 0;    
		//console.log('stateChangeSuccess');
  });
}]);