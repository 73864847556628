'use strict';

angular.module('poi360.views.press', [])

.config(['$stateProvider', function($stateProvider) {

	$stateProvider.state('press', {
        url: "/press",
        templateUrl: 'scripts/app/views/press/press.html',
        controller: 'PressCtrl',
		onEnter: ["progressBar", function(progressBar){
			var progressComplete = progressBar.complete();
		}]
    });
}])

.controller('PressCtrl', ['$scope', '$animate', 'appHeaderFactory', 'animationSettings', function($scope, $animate, appHeaderFactory, animationSettings){

	$scope.pressImages = [
		'POI360-KahurangiMaxwell4.jpg',
		'POI360-L-RTalei Morrison,Kahurangi Maxwell,Tiria Waitai.jpg',
		'POI360-Official.jpg',
		'POI360-Takawirihanga.jpg',
		'POI360-Takawirihangawithlogo.jpg',
		'POI360-TaleiMorrison.jpg',
		'POI360-TanikoandRaupoPoiatTePapa.jpg',
		'POI360-TeArawaKuia.jpg',
		'POI360-TiriaWaitai.jpg'
	];

	animIn();

	function animIn(){
		var $page = $('.press');

		var revealTl = new TimelineMax();
		
		revealTl.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease, onComplete: animComplete});
	}
	
	function animComplete(){
		appHeaderFactory.showHeader(true);

		var revealTl = new TimelineMax();
		
		revealTl.to($('.extended-footer'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease});
	}
}]);