'use strict';

angular.module('poi360.components.owlcarousel', [])

.directive('homepageOwlCarousel', function(){
	return {
		templateUrl: 'scripts/app/views/homepage/homepage-slider.html',
		controller: ['$scope', 'animationSettings', function($scope, animationSettings){
			// DOCO ANIMATION
			var quicklinkDocoTl = new TimelineMax({paused: true});
			var quicklinkDoco = $('.homepage__quick-links-anchor--doco');
			var quicklinkDocoDefault = $('.homepage__quick-links-icon-doco-path--default');
			var quicklinkDocoHover = $('.homepage__quick-links-icon-doco-path--hover');
			var quicklinkDocoBg = $(quicklinkDoco).find('.homepage__quick-links-item-bg');
			var quicklinkDocoSubtitle = $(quicklinkDoco).find('.homepage__quick-links-subtitle');

			quicklinkDocoTl.set(quicklinkDocoSubtitle, {opacity: 0})
				.set(quicklinkDocoHover, {opacity: 0, scale: 0, transformOrigin: "center"});

			quicklinkDocoTl.to(quicklinkDocoDefault, animationSettings.homepageTiles.paths.speed, {opacity: 0, scale: 0, ease: animationSettings.homepageTiles.paths.easeIn}, "0")
				.to(quicklinkDocoHover, animationSettings.homepageTiles.paths.speed, {opacity: 1, scale: 1.5, ease: animationSettings.homepageTiles.paths.easeOut})
				.to(quicklinkDocoBg, animationSettings.homepageTiles.background.speed, {opacity: animationSettings.homepageTiles.background.opacity, ease: animationSettings.homepageTiles.background.ease}, "0")
				.to(quicklinkDocoSubtitle, animationSettings.homepageTiles.background.speed, {opacity: 1,  ease: animationSettings.homepageTiles.background.ease}, "0");

			// 360 ANIMATION
			var quicklink360TlEnter = new TimelineMax({paused: true});
			var quicklink360TlLeave = new TimelineMax({paused: true});
			var quicklink360 = $('.homepage__quick-links-anchor--360');
			var quicklink360Bg = $(quicklink360).find('.homepage__quick-links-item-bg');
			var quicklink360Subtitle = $(quicklink360).find('.homepage__quick-links-subtitle');
			var quicklink360Arrows = $(quicklink360).find('.homepage__quick-links-icon-360-arrows');

			quicklink360TlEnter.set(quicklink360Bg, {opacity: 0})
				.set(quicklink360Subtitle, {opacity: 0})
				.set(quicklink360Arrows, {transformOrigin: "35px center"});

			quicklink360TlEnter.to(quicklink360Bg, animationSettings.homepageTiles.background.speed, {opacity: animationSettings.homepageTiles.background.opacity, ease: animationSettings.homepageTiles.background.ease}, "start")
				.to(quicklink360Subtitle, animationSettings.homepageTiles.background.speed, {opacity: 1, ease: animationSettings.homepageTiles.background.ease}, "0")
				.to(quicklink360Arrows, animationSettings.homepageTiles.background.speed, {scale: 1.3, rotation: "-360", ease: animationSettings.ease.easeInOut}, "start")
				.to(quicklink360Arrows, 0.3, {scale: 1, ease: animationSettings.ease.easeOut});

			quicklink360TlLeave.to(quicklink360Bg, animationSettings.homepageTiles.background.speed, {opacity: 0, ease: animationSettings.homepageTiles.background.ease}, "start+="+animationSettings.homepageTiles.background.speed)
				.to(quicklink360Subtitle, animationSettings.homepageTiles.background.speed, {opacity: 0, ease: animationSettings.homepageTiles.background.ease}, "start+="+animationSettings.homepageTiles.background.speed);

			//MAP ANIMATION
			var quicklinkMapTl = new TimelineMax({paused: true});
			var quicklinkMap = $('.homepage__quick-links-anchor--map');
			var quicklinkMapMarkers = '.homepage__quick-links-icon-map-marker';
			var quicklinkMapBg = $(quicklinkMap).find('.homepage__quick-links-item-bg');
			var quicklinkMapSubtitle = $(quicklinkMap).find('.homepage__quick-links-subtitle');

			quicklinkMapTl.set(quicklinkMapMarkers, {opacity: 0, y: "-10px"})
				.set(quicklinkMapSubtitle, {opacity: 0}); 

			quicklinkMapTl.staggerTo(quicklinkMapMarkers, animationSettings.homepageTiles.paths.speed * 0.5, {opacity:1, y: "0", ease: animationSettings.homepageTiles.paths.easeOut}, animationSettings.homepageTiles.paths.speed * 0.5)
				.to(quicklinkMapBg, animationSettings.homepageTiles.background.speed, {opacity: animationSettings.homepageTiles.background.opacity, ease: animationSettings.homepageTiles.background.ease}, "0")
				.to(quicklinkMapSubtitle, animationSettings.homepageTiles.background.speed, {opacity: 1, ease: animationSettings.homepageTiles.background.ease}, "0");

			//Hover States
			$scope.quicklinkMouseEnter = function(item){
				if(item == 'map'){
					quicklinkMapTl.play();
				}else if(item == 'doco'){
					quicklinkDocoTl.play();
				}else if(item == '360'){
					quicklink360TlEnter.play("start");
				}
				
			};

			$scope.quicklinkMouseLeave = function(item){
				if(item == 'map'){
					quicklinkMapTl.reverse();
				}else if(item == 'doco'){
					quicklinkDocoTl.reverse();
				}else if(item == '360'){
					quicklink360TlLeave.play("start");
				}
			};
		}], 
		link: function(scope, element, attrs){
			$(element).owlCarousel({
				responsive: {
				    0 : {
				        items : 2,
				        loop: false,
				        margin: 40,
				        center: true,
				        smartSpeed: 400
				    },
				    768 : {
				        items: 3
				    }
				}
			});	
		}
	};
});
