'use strict';

angular.module('poi360.components.loading-screen', ['angular-svg-round-progressbar'])

.directive('loadingScreen', [function(){
	return {
		templateUrl: "scripts/app/components/loading-screen/loading-screen.html",
		controller: ["$scope", "$rootScope", "pageFactory", "$http", function($scope, $rootScope, pageFactory, $http){
			$scope.progressBar = $rootScope.progessBar;
			$scope.currentPage = pageFactory.getCurrentPage();

			$scope.$watch(function(){return pageFactory.currentPage}, function(NewValue, OldValue){
				$scope.currentPage = pageFactory.getCurrentPage();
			});
		}]
	}
}]);