'use strict';

angular.module('poi360.views.interview-profile', [])

.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('interview-profile', {
        url: "/interview-profile/:slug",
        templateUrl: 'scripts/app/views/interview-profile/interview-profile.html',
	    controller: 'InterviewProfileCtrl',
	    loadItems: 0
    });
}])

.controller('InterviewProfileCtrl', ['$scope', 'animationSettings', '$animate', 'appHeaderFactory', '$http', '$stateParams', '$filter', '$location', '$sce', 'helpers', function($scope, animationSettings, $animate, appHeaderFactory, $http, $stateParams, $filter, $location, $sce, helpers) {

	var slug = $stateParams.slug;
	var interview = [];
	var interviewIndex;

	dataCall();

	function dataCall(){
  		var revealTl = new TimelineMax();
		revealTl.to($('.ajax-loader'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease})

  		$http({
		  method: 'GET',
		  url: '/data/interviews.json'
		}).then(function successCallback(response) {
		    $scope.interviews = response.data.interviews;
		    interview = $filter("filter")($scope.interviews, {slug: slug}, true);

		    if(interview.length > 0){
		    	$scope.interview = interview[0];		    
		    	$scope.interview.player_config.currentQualitySource = $scope.interview.player_config.qualitySources[1]
		    	
		    	$scope.articlePartial = 'scripts/app/views/interview-profile/articles/'+$scope.interview.slug+'.html';

		    	interviewIndex = helpers.arrayObjectIndexOf($scope.interviews, $scope.interview, 'slug');
		    	$scope.interviews.splice(interviewIndex, 1);

		    	animIn();	
		    }else{
		    	$location.url('/')
		    }
		    
		  }, function errorCallback(response) {
		    // called asynchronously if an error occurs
		    // or server returns response with an error status.
		  });
  	}

  	function animIn(){
  		var $page = $('.interview-profile');

  		var revealTl = new TimelineMax();
  		revealTl.to($('.ajax-loader'), animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.fadeIn.ease})
  			.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease, onComplete: animComplete})
  			.to($('.extended-footer'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease});
  	}

  	function animComplete(){
		appHeaderFactory.showHeader(true);

	}

}]);