'use strict';

angular.module('poi360.components.header.header-directive', [])

.directive('appHeader', function(){
	return {
		templateUrl: "scripts/app/components/header/header.html",
		controller: ['$scope', 'appHeaderFactory', 'appMenuFactory', 'pageFactory', function($scope, appHeaderFactory, appMenuFactory, pageFactory){
			$scope.showHeader = appHeaderFactory.getHeaderState();
			$scope.menuOpen = appMenuFactory.getMenuState();
			$scope.menuHoverText = null;
			$scope.menuHoverShow = false;

			$scope.currentPage = pageFactory.getCurrentPage();

			$scope.$watch(function(){return pageFactory.currentPage}, function(NewValue, OldValue){
				$scope.currentPage = pageFactory.getCurrentPage();
			});

			$scope.showMenu = function(state){
				appMenuFactory.showMenu(state);
			}

			var updateShowHeader = function(){
			    $scope.showHeader = appHeaderFactory.getHeaderState();
			    $scope.$applyAsync();
			};

			var updateShowMenu = function(){
			    $scope.menuOpen = appMenuFactory.getMenuState();
			};

			appMenuFactory.registerObserverCallback(updateShowMenu);
			appHeaderFactory.registerObserverCallback(updateShowHeader);
		}]
	};
})

.directive('appHeaderMenu', function(){
	return {
		templateUrl: "scripts/app/components/header/header-menu.html",
		controller: ['$scope', 'appMenuFactory', function($scope, appMenuFactory){
			$scope.menuOpen = appMenuFactory.getMenuState();

			$scope.showMenu = function(state){
				appMenuFactory.showMenu(state);
			}

			var updateShowMenu = function(){
			    $scope.menuOpen = appMenuFactory.getMenuState();
			};

			appMenuFactory.registerObserverCallback(updateShowMenu);
		}]
	};
});
