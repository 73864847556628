'use strict';

angular.module('poi360.components.info-box', [])

.directive('infoBox', [function(){
	return {
		templateUrl: "scripts/app/components/info-box/info-box.html",
		link: function(scope, element, attrs, API){
			
		}
	};
}]);