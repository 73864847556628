'use strict';

angular.module('poi360.views.interviews-profiles', [])

.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('interviews-profiles', {
        url: "/interviews-profiles",
        templateUrl: 'scripts/app/views/interviews-profiles/interviews-profiles.html',
	    controller: 'InterviewsProfilesCtrl',
	    loadItems: 0
    });
}])

.controller('InterviewsProfilesCtrl', ['$scope', 'animationSettings', '$animate', 'appHeaderFactory', '$http', '$timeout', function($scope, animationSettings, $animate, appHeaderFactory, $http, $timeout) {

	$scope.interviews = [];

	animIn();

  	function animIn(){
  		var $page = $('.interviews-profiles');
  		var splitText = new SplitText('.interviews-profiles__intro-p', {type: "words, chars"});

  		var revealTl = new TimelineMax();
  		revealTl.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease})
  			.to($('.interviews-profiles__intro'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease})
			.from($('.interviews-profiles__intro-h1'), animationSettings.intro.title.speed, {opacity: 0, y: animationSettings.intro.title.y, ease: animationSettings.intro.title.ease})
			.staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed}, animationSettings.intro.splitText.charStagger, animationSettings.intro.splitText.offset, animComplete);

  	}

  	function animComplete(){
		appHeaderFactory.showHeader(true);
		dataCall();
	}

  	function dataCall(){
  		var revealTl = new TimelineMax();
		revealTl.to($('.interviews-profiles__loading'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease})

  		$http({
		  method: 'GET',
		  url: '/data/interviews.json'
		}).then(function successCallback(response) {
		    $scope.interviews = response.data.interviews;
		    // setTimeout(function(){
		    	animTiles();
		    // }, 1000)
		  }, function errorCallback(response) {
		    // called asynchronously if an error occurs
		    // or server returns response with an error status.
		  });
  	}

	var animTiles = function(){
		var revealTl = new TimelineMax();
		revealTl.to($('.extended-footer'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease});
	}
}]);