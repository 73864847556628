'use strict';

angular.module('poi360.animations', ['ngAnimate'])

.animation('.loading-screen', ['animationSettings', function(animationSettings){
  return {
    enter: function(element, doneFn) {
      var splitText = new SplitText('.loading-screen__p', {type: "words, chars"});

      var tl = new TimelineMax({delay: 0});

      tl.from(element, animationSettings.loadingPage.speedIn, {opacity: 0, ease: animationSettings.loadingPage.ease})
        .from($('.loading-screen__h1'), animationSettings.intro.title.speed, {opacity: 0, y: animationSettings.intro.title.y, ease: animationSettings.intro.title.ease})
		.staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed, onComplete: doneFn}, animationSettings.intro.splitText.charStagger, animationSettings.intro.splitText.offset);
    },
    leave: function(element, doneFn) {
      var tl = new TimelineMax({delay: animationSettings.page.speed});
      tl.to(element, animationSettings.loadingPage.speedOut, {opacity: 0, ease: animationSettings.loadingPage.ease, onComplete: doneFn});
    }
  }
}])
.animation('.animate-ui-view', ['animationSettings', function(animationSettings) {
  return {
    enter: function(element, doneFn) {
      var tl = new TimelineMax({delay: animationSettings.page.speed});
      tl.set(element, {position: 'absolute', opacity:0});
      tl.to(element, animationSettings.page.speed, {position: 'inherit', opacity: 1, ease: Power0.easeNone, onComplete: doneFn});
    },
    leave: function(element, doneFn) {
      var tl = new TimelineMax();
      tl.to(element, animationSettings.speed.fast, {opacity: 0, ease: animationSettings.fadeIn.ease, onComplete: doneFn});
    }
  }
}])
.animation('.header', ['animationSettings', function(animationSettings) {
	return {
	    enter: function(element, doneFn) {
	      var tl = new TimelineMax();
	      tl.set(element, {opacity: 0, y: -animationSettings.fadeIn.y});
	      tl.to(element, animationSettings.fadeIn.speed, {opacity: 1, y: 0, ease: animationSettings.fadeIn.ease, onComplete: doneFn});
	    }
  	}
}])
.animation('.anim-title-description', ['animationSettings', function(animationSettings){
	return {
	    enter: function(element, doneFn) {
			var tl = new TimelineMax();
			var splitText = new SplitText('.anim-title-description__p', {type: "words, chars"});

			tl.from(element, animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.fadeIn.ease})
		        .from($('.anim-title-description__h1'), animationSettings.intro.title.speed, {opacity: 0, y: animationSettings.intro.title.y, ease: animationSettings.intro.title.ease})
				.staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed, onComplete: doneFn}, animationSettings.intro.splitText.charStagger, animationSettings.intro.splitText.offset);
		},
    	leave: function(element, doneFn) {
    		var tl = new TimelineMax();
	      	tl.to(element, animationSettings.fadeIn.speed, {opacity: 0, y: animationSettings.fadeIn.y, ease: animationSettings.fadeIn.ease, onComplete: doneFn});
    	}
    }

}])
.animation('.anim-text-reveal', ['animationSettings', function(animationSettings){
  return {
    enter: function(element, doneFn) {
      var tl = new TimelineMax();
      var splitText = new SplitText(element, {type: "words, chars"});

      tl.staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed, onComplete: doneFn}, animationSettings.intro.splitText.charStagger, animationSettings.intro.splitText.offset, doneFn);
    },
      leave: function(element, doneFn) {
        var tl = new TimelineMax();
          tl.to(element, animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.fadeIn.ease, onComplete: doneFn});
      }
    }
}])
.animation('.anim-menu-text-reveal', ['animationSettings', function(animationSettings){
  return {
    enter: function(element, doneFn) {
      var tl = new TimelineMax();
      var splitText = new SplitText(element, {type: "words, chars"});

      tl.staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed, onComplete: doneFn}, animationSettings.intro.splitText.charStagger, animationSettings.intro.splitText.offset, doneFn);
    },
      leave: function(element, doneFn) {
        var tl = new TimelineMax();
          tl.to(element, 0.05, {opacity: 0, ease: animationSettings.fadeIn.ease, onComplete: doneFn});
      }
    }
}])
.animation('.anim-modal', ['animationSettings', function(animationSettings){
  return {
    enter: function(element, doneFn) {
      var overlay = $(element).find('.modal__overlay');
      var modal = $(element).find('.modal__modal-box');
      var tl = new TimelineMax();

      tl.set(overlay, {opacity: 0})
        .set(modal, {opacity: 0});

      tl.to(overlay, animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease}, "modal")
        .to(modal, animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.modal.ease, onComplete: doneFn}, "modal");
    },
      leave: function(element, doneFn) {
        var overlay = $(element).find('.modal__overlay');
        var modal = $(element).find('.modal__modal-box');
        var tl = new TimelineMax();

        tl.to(modal, animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.modal.ease}, "modal")
          .to(overlay, animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.fadeIn.ease, onComplete: doneFn}, "modal");
      }
    }
}])
.animation('.anim-info-box', ['animationSettings', function(animationSettings){
  return {
    enter: function(element, doneFn) {
      var tl = new TimelineMax();

      tl.set(element, {opacity: 0, x: "-100%"});
      tl.to(element, animationSettings.modal.speed, {opacity: 1, x: "0%", ease: animationSettings.modal.ease, onComplete: doneFn});
    },
      leave: function(element, doneFn) {
        var tl = new TimelineMax();
        tl.to(element, animationSettings.modal.speed, {opacity: 0, x: "-100%", ease: animationSettings.modal.ease, onComplete: doneFn});
      }
    }
}])
.animation('.anim-fade-down', ['animationSettings', function(animationSettings){
  return {
    enter: function(element, doneFn) {
      var tl = new TimelineMax();
        tl.set(element, {opacity: 0, y: -animationSettings.fadeIn.y});
        tl.to(element, animationSettings.fadeIn.speed, {opacity: 1, y: 0, ease: animationSettings.fadeIn.ease, onComplete: doneFn});  
    },
    leave: function(element, doneFn) {
        var tl = new TimelineMax();
        tl.to(element, animationSettings.fadeIn.speed, {opacity: 0, y: -animationSettings.fadeIn.y, ease: animationSettings.fadeIn.ease, onComplete: doneFn});
    }
  }
}])
.animation('.anim-header-menu', ['animationSettings', function(animationSettings){
  var menuTl = function(element, doneFn){

  }

  return {
    enter: function(element, doneFn) {
      var menuBg = $(element).find('.header__menu-bg');
      var menuItems = $(element).find('.header__menu-main-list-item');
      var menuTitle = $(element).find('.header__menu-main-title');
      var menuCloseIcon = $(element).find('.header__menu-close-icon');
      var menuFooter = $(element).find('.header__menu-footer');
      var menuEndOffset = 0.3;

      var tl = new TimelineMax();
      tl.set(menuBg, {rotation: 90, x: "-100%"})
        .set(menuItems, {opacity: 0, x: -animationSettings.list.x})
        .set(menuTitle, {opacity: 0})
        .set(menuCloseIcon, {opacity: 0})
        .set(menuFooter, {opacity: 0});

      tl.to(menuBg, animationSettings.menu.speed, {rotation: 0, x: "0%", ease: animationSettings.menu.ease})
        .add("inner", "-="+animationSettings.menu.speed/2)
        .staggerTo(menuItems, animationSettings.list.speed, {opacity: 1, x: 0, ease: animationSettings.list.ease}, animationSettings.list.stagger, "inner")
        .to(menuFooter, animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease}, "inner")
        .to(menuTitle, animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease}, "inner")
        .to(menuCloseIcon, animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease, onComplete: doneFn}, "inner");
    },
    leave: function(element, doneFn) {
        var menuBg = $(element).find('.header__menu-bg');
        var menuItems = $(element).find('.header__menu-main-list-item');
        var menuTitle = $(element).find('.header__menu-main-title');
        var menuCloseIcon = $(element).find('.header__menu-close-icon');
        var menuFooter = $(element).find('.header__menu-footer');
        var menuFeature = $(element).find('.header__menu-hover-feature');

        var tl = new TimelineMax();

        tl.to(menuCloseIcon, animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.fadeIn.ease}, "inner")
          .to(menuTitle, animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.fadeIn.ease}, 
            "inner")
          .to(menuFooter, animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.fadeIn.ease}, "inner")
          .to(menuFeature, animationSettings.fadeIn.speed/2, {opacity: 0, ease: animationSettings.fadeIn.ease}, "inner")
          .staggerTo(menuItems, animationSettings.list.speed, {opacity: 0, x: -animationSettings.list.x, ease: animationSettings.list.ease}, animationSettings.list.stagger, "inner")
          .to(menuBg, animationSettings.menu.speed, {rotation: 90, x: "-100%", ease: animationSettings.menu.ease, onComplete: doneFn}, "-="+animationSettings.menu.speed/2);
    }
  }
}]);