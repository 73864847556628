'use strict';

angular.module('poi360.views.learn-how-to-poi', [])

.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('learn-how-to-poi', {
        url: "/learn-how-to-poi",
        templateUrl: 'scripts/app/views/learn-how-to-poi/learn-how-to-poi.html',
	    controller: 'LearnHowToPoiCtrl',
		loadItems: 0
    });
}])

.controller('LearnHowToPoiCtrl', ['$scope', 'animationSettings', '$animate', 'appHeaderFactory', '$http', '$timeout', '$location', '$anchorScroll', function($scope, animationSettings, $animate, appHeaderFactory, $http, $timeout, $location, $anchorScroll) {

	animIn();

	function animIn(){
  		var $page = $('.learn-how-to-poi');
  		var splitText = new SplitText('.learn-how-to-poi__intro-p', {type: "words, chars"});

  		var revealTl = new TimelineMax();
  		revealTl.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease})
  			.to($('.learn-how-to-poi__intro'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease})
			.from($('.learn-how-to-poi__intro-h1'), animationSettings.intro.title.speed, {opacity: 0, y: animationSettings.intro.title.y, ease: animationSettings.intro.title.ease})
			.staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed}, animationSettings.intro.splitText.charStagger, animationSettings.intro.splitText.offset, animComplete);

  	}

  	function animComplete(){
		appHeaderFactory.showHeader(true);
		dataCall();
	}

  	function dataCall(){
  		var revealTl = new TimelineMax();
		revealTl.to($('.video-gallery__loading'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease})

  		$http({
		  method: 'GET',
		  url: '/data/how-to-poi.json'
		}).then(function successCallback(response) {
		    $scope.videos = response.data.videos;
			
		    // setTimeout(function(){
		    	animFooter();
		    // }, 1000)
		  }, function errorCallback(response) {
		    // called asynchronously if an error occurs
		    // or server returns response with an error status.
		  });
  	}

  	var animFooter = function(){
		var revealTl = new TimelineMax();

		revealTl.to($('.extended-footer'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease});
	}

}]);