'use strict';

angular.module('poi360.components.interviews-profiles-previews.interviews-profiles-previews', [])

.directive('interviewsProfilesPreviews', function(){
	return {
		scope: {
			interviews: "="
		},
		templateUrl: "scripts/app/components/interviews-profiles-previews/interviews-profiles-previews.html",
		controller: ['$scope', 'animationSettings', function($scope, animationSettings){
			
			$scope.$watch('interviews', function(){
				setTimeout(function(){
					animTiles();				
				}, 100);
			});

			var animTiles = function(){
				var tiles = $('.interviews-profiles-preview__wrapper');
				var revealTl = new TimelineMax();
				revealTl.set(tiles, {y: animationSettings.tiles.y});	
				revealTl.to($('.interviews-profiles__loading'), animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.fadeIn.ease})
					.staggerTo(tiles, animationSettings.tiles.speed, {opacity: 1, y: 0, ease: animationSettings.tiles.ease}, animationSettings.tiles.stagger);
			}
		}]
	};
});
