'use strict';

angular.module('poi360.components.sharebar.sharebar-directive', [])

.directive('appShareBar', ['$timeout', 'pageFactory', function($timeout, pageFactory){
	return {
		scope: {
			sharePage: "="
		},
		templateUrl: "scripts/app/components/sharebar/sharebar.html",
		link: function(scope, element, attrs){
			scope.showShare = false;
			var mouseLeaveTimeout;

			scope.siteUrl = encodeURIComponent(window.location.origin+'/#!');

			scope.thisMouseOver = function(){
				$timeout.cancel(mouseLeaveTimeout);
				scope.showShare = true;
			}

			scope.thisMouseLeave = function(){
				$timeout.cancel(mouseLeaveTimeout);

				mouseLeaveTimeout = $timeout(function(){
					scope.showShare = false;
				}, 600);
			}

			function shareDialog() {
			  if (window.__twitterIntentHandler) return;
			  var twitterIntentRegex = /twitter\.com\/intent\/(\w+)/,
			  	  facebookIntentRegex = /facebook\.com\/sharer\/(\w+)/,
			      windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes',
			      width = 550,
			      height = 420,
			      winHeight = screen.height,
			      winWidth = screen.width;
			 
			  function handleIntent(e) {
			    e = e || window.event;
			    var target = e.target || e.srcElement,
			        m, left, top;
			 
			    while (target && target.nodeName.toLowerCase() !== 'a') {
			      target = target.parentNode;
			    }
			 
			    if (target && target.nodeName.toLowerCase() === 'a' && target.href) {
			      m = target.href.match(twitterIntentRegex) || target.href.match(facebookIntentRegex);

			      if (m) {
			        left = Math.round((winWidth / 2) - (width / 2));
			        top = 0;
			 
			        if (winHeight > height) {
			          top = Math.round((winHeight / 2) - (height / 2));
			        }
			 
			        window.open(target.href, 'intent', windowOptions + ',width=' + width +
			                                           ',height=' + height + ',left=' + left + ',top=' + top);
			        e.returnValue = false;
			        e.preventDefault && e.preventDefault();
			      }
			    }
			  }
			 
			  if (document.addEventListener) {
			    document.addEventListener('click', handleIntent, false);
			  } else if (document.attachEvent) {
			    document.attachEvent('onclick', handleIntent);
			  }
			  window.__twitterIntentHandler = true;
			};

			shareDialog();
		}
	};
}]);
