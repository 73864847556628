'use strict';

angular.module('poi360.components.video-gallery.video-gallery', [])

.directive('videoGallery', [function(){
	return {
		scope: {
			videos: "="
		},
		templateUrl: "scripts/app/components/video-gallery/video-gallery.html",
		controller: ['$scope', 'animationSettings', '$location', '$anchorScroll', function($scope, animationSettings, $location, $anchorScroll){
			$scope.featured = null;

			$scope.thumbClick = function($event, $index, video){
				if(typeof video.player_config.currentQualitySource === 'undefined'){
					video.player_config.currentQualitySource = video.player_config.qualitySources[1];
				}
				$scope.featured = video;
				$scope.featuredIndex = $index;

				$location.hash('play');
		      	$anchorScroll();
			}
			
			$scope.arrowClick = function(direction){
				var newIndex;
				var featuredIndex = $scope.featuredIndex;
				var totalVideos = $scope.videos.length;

				if(direction == 'prev'){
					if(featuredIndex === 0){
						newIndex = totalVideos - 1;
					}else{
						newIndex = featuredIndex - 1;
					}
				}else{
					if(featuredIndex === totalVideos - 1){
						newIndex = 0;
					}else{
						newIndex = featuredIndex + 1;
					}
				}

				if(typeof $scope.videos[newIndex].player_config.currentQualitySource === 'undefined'){
					$scope.videos[newIndex].player_config.currentQualitySource = $scope.videos[newIndex].player_config.qualitySources[1];
				}
				$scope.featured = $scope.videos[newIndex];
				$scope.featuredIndex = newIndex;
			}

			$scope.$watch('videos', function(newVal, oldVal){
				if(typeof newVal !== 'undefined'){
					setTimeout(function(){
						animTiles();	
					}, 2000);
				}
			});

			function animTiles(){
				var tiles = $('.video-gallery__preview-wrapper');

				var revealTl = new TimelineMax();

				revealTl.set(tiles, {y: animationSettings.tiles.y});		
				revealTl.to($('.video-gallery__loading'), animationSettings.fadeIn.speed, {opacity: 0, ease: animationSettings.fadeIn.ease})
					.staggerTo(tiles, animationSettings.tiles.speed, {opacity: 1, y: 0, ease: animationSettings.tiles.ease}, animationSettings.tiles.stagger);
			}
		}]
	}
}]);