'use strict';

angular.module('poi360.views.contact', ["ui.router"])

.config(['$stateProvider', function($stateProvider) {

	$stateProvider.state('contact', {
        url: "/contact",
        templateUrl: 'scripts/app/views/contact/contact.html',
        controller: 'ContactCtrl',
		loadItems: 0
    });
}])

.controller('ContactCtrl', ['$scope', '$animate', 'appHeaderFactory', 'animationSettings', function($scope, $animate, appHeaderFactory, animationSettings){
	
	animIn();

	function animIn(){
		var $page = $('.contact');

		var revealTl = new TimelineMax();
		
		revealTl.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease, onComplete: animComplete});
	}
	
	function animComplete(){
		appHeaderFactory.showHeader(true);

		var revealTl = new TimelineMax();
		
		revealTl.to($('.extended-footer'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease});
	}
}]);