'use strict';

angular.module('poi360.components.footer.footer-directive', [])

.directive('appFooter', function(){
	return {
		templateUrl: "scripts/app/components/footer/footer.html",
		controller: ["$location", function($location){

		}]
	};
})
.directive('appExtendedFooter', function(){
	return {
		templateUrl: "scripts/app/components/footer/extended-footer.html",
		link: function(scope, element, attrs){
			scope.quicklinks = {
				storyOfCreation: true,
				poi360Experience: true,
				exploreAotearoa: false
			}
		}
	};
});
