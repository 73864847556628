'use strict';

angular.module('poi360.components.explore-map', [])

.directive('exploreMap', ["$sce", "$animate", function($sce, $animate, $window){
	return {
		scope: {
			story: "="
		},
		template: '<div id="cesiumContainer" class="explore-map"><clouds></clouds><info-box></info-box><welcome-tooltip></welcome-tooltip><map-nav entities="entities"></map-nav></div>',
		controller: ["$scope", "mobileDetection", "soundFactory", function($scope, mobileDetection, soundFactory){
			// Cesium.BingMapsApi.defaultKey = 'AsDSIiiEyqxI17agGI7LUTitQvY6I0TF16YHXdX_RDVJgIkA-DX7eyC-_tRx6miZ'; // Dev API
			Cesium.BingMapsApi.defaultKey = 'AvUaCpUNJUv-geVklKmnnm-qdU7CrXUNDobswq9uZFzWtASMehpwdNuP35nARHo8';

			var viewerOptions = {
				timeline: false,
				animation: false,
				baseLayerPicker: false,
				fullscreenButton: false,
				geocoder: false,
				homeButton: false,
				infoBox: false,
				sceneModePicker: false,
				selectionIndicator: false,
				navigationHelpButton: false,
				navigationInstructionsInitiallyVisible: false,
				scene3DOnly: true,
				terrainExaggeration: 3,
			};

			Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwMTdmZDNlOC1lNWFjLTQ4NGQtOWMzYS1hZDY5MzhhYzBhNWEiLCJpZCI6NjExMCwic2NvcGVzIjpbImFzciIsImdjIl0sImlhdCI6MTU0NTI2MjE0N30.IbIPUmNf05vlXNh1_er7cAKBSczvI_-9x761FRiEKbM';
			var viewer = new Cesium.Viewer('cesiumContainer', viewerOptions);
			var scene = viewer.scene;
			var inFlight = 0;
			var isMobile = mobileDetection.isMobile();

			$scope.activeEntity = null;
			$scope.modal = {
				open: false,
				showButton: false
			};
			$scope.welcomeTooltip = false;

			$scope.modalClose = function(){
				$scope.modal.open = false;
				soundFactory.playLoop('background');
				$scope.modal.showButton = false;
				$scope.flyToLocation('default', true);
			}

			//CONTROL SETTINGS
			viewer.scene.screenSpaceCameraController.enableZoom = false;
			viewer.scene.screenSpaceCameraController.enableTilt = false; 
			viewer.scene.screenSpaceCameraController.enableRotate = false;

			//TERRAIN
			if(!isMobile){
				var terrainProvider = new Cesium.CesiumTerrainProvider({
					url: Cesium.IonResource.fromAssetId(1)
				});
				viewer.terrainProvider = terrainProvider;
			}

			//USER INPUT HANDLERS
			var handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
			handler.setInputAction(
				function(windowPosition) {
					var pickedEntity = drillPickEntities(windowPosition.position);

					if(pickedEntity.length > 0){
						var entity = pickedEntity[0];
						
						$scope.activeEntity = null;
						$scope.modal.open = false;
						$scope.welcomeTooltip = false;
						$scope.$apply();

						flyToEntity(entity);
					}
           		},
           		Cesium.ScreenSpaceEventType.LEFT_DOWN
        	);

        	var forbiddenLoveConfig = {
				sources: [
					{src: $sce.trustAsResourceUrl("http://static.videogular.com/assets/videos/videogular.mp4"), type: "video/mp4"},
					{src: $sce.trustAsResourceUrl("http://static.videogular.com/assets/videos/videogular.webm"), type: "video/webm"},
					{src: $sce.trustAsResourceUrl("http://static.videogular.com/assets/videos/videogular.ogg"), type: "video/ogg"}
				],
		        plugins: {
		            poster: {
		                url: "images/placeholder/placeholder-1.png"
		            }
		        }
			}

			//INIT CAMERA SETTING
			// x: 174.777222, (Latitude)
			// y: -41.288889, (longitude)
			// z: 150000, (height)
			// heading: Cesium.Math.toRadians(0), (0 = North, 90 = East, etc)
			// pitch: Cesium.Math.toRadians(-30) (0 = horizontal, 90 = birdseye)

			var cityPitch = Cesium.Math.toRadians(-35);
			var cityRange = 50000;
			var billboards = {
				blue: {
			      image : '/images/markers/poi-marker-blue.png',
			      width : 50,
			      height : 54
			  	},
			  	red: {
			      image : '/images/markers/poi-marker-red.png',
			      width : 50,
			      height : 54
			  	},
			  	purple: {
			      image : '/images/markers/poi-marker-purple.png',
			      width : 50,
			      height : 54 
			  	},
			  	orange: {
			      image : '/images/markers/poi-marker-orange.png',
			      width : 50,
			      height : 54
			  	},
			  	green: {
			      image : '/images/markers/poi-marker-green.png',
			      width : 50,
			      height : 54
			  	},
			  	yellow: {
			      image : '/images/markers/poi-marker-yellow.png',
			      width : 50,
			      height : 54
			  	}

			};

			var locations = {
				default: {
					x: 174.777222,
					y: -41.288889, 
					heading: Cesium.Math.toRadians(0), 
					pitch: Cesium.Math.toRadians(-70),
					range: 3000000
				},
				KimikimiMane: {
					title: 'Kimikimi Mane',
					message: "A poi isn’t just a toy, it’s a passion. For me a poi is a woman thing, but I ngā rā o mua it was a men’s weapon. Now it has been adapted with grace and beauty by our female folk. For me, if it’s not graceful and it can’t tell me a story…I don’t want to know about it.The poi to me is about storytelling, and if you can sell your story with poi to a deaf person, then you know you’ve nailed it on the head.",
					x: 174.7308132,
					y: -36.8668367, 
					billboard : billboards.blue,
					bannerColor: 'blue',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Kimikimi_photo.jpg'
                    }
				},
				WikitoriaTuhakarainaSimon: {
					title: 'Wikitoria Tuhakaraina-Simon',
					message: "Nō Ngāti Hauā, Te Whakatōhea, Ngāi Tūhoe me Ngāti Rangiwewehi. Poi saved my life. Poi is my life. Poi is my passion. Poi is the healing tool that keeps me balanced within my four iwi. Poi is a representation of life and new beginnings.",
					x: 176.2185288,
					y: -38.0501825, 
					billboard : billboards.red,
					bannerColor: 'red',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Yve_photo.jpg'
                    }
				},
				MahiaGreen: {
					title: 'Mahia (Lucky) Green',
					message: "He Taonga tuatahi, It's is a healing tool, it's a story teller, It's a heart beat, It's a heritage, It's a history, It's a feature, It's my best friend. POI",
					x: 173.8033217,
					y: -39.2865407,
					billboard : billboards.purple,
					bannerColor: 'purple',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Mahia_photo.jpg'
                    }
				},
				PiataWaitai: {
					title: 'Piata Waitai',
					message: "I whānau mai ahau ki roto i tētahi whānau e ngana ana ki te mahi kapa haka. I tenei wā kua waihanga poi mō ngā kura huhua puta noa i te Tai Rawhiti. Te Kura o Manutuke, Tūranga wāhine Tūranga Tāne, Horouta Wānanga, Manawa Tahi, me te kapa o Tū Te Manawa Maurea.",
					x: 177.9074439,
					y: -38.68014,
					billboard : billboards.blue,
					bannerColor: 'blue',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Piata_photo.png'
                    }
				},
				TaniaKiel: {
					title: 'Tania Kiel',
					message: "He uri ahau nō ngā kawai whakapapa o Te Arawa, Te Aitanga a Hauiti, Ngāti Raukawa, Ngāti Whare me Ngāti Manawa. I tipu ake au i Te Rotorua-nui-a-Kahumatamomoe, aa i reira i ako ai au i tēnei tāonga kamehameha, te Poi. Ko Rakei rāua ko Georgina Fraser ōku mātua, ko Tania Rawinia Kiel ahau. Poi is a connection to my tupuna and reminds me of the core values that have been instilled in me. It is an implement like no other traditional Māori implement. It becomes an extension of your body that stimulates all senses and overall enriches your tinana, hinengaro and wairua.",
					x: 176.2473556,
					y: -38.156616,
					billboard : billboards.red,
					bannerColor: 'red',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Tania_photo.jpg'
                    }
				},
				SidneyRewetiElliott: {
					title: 'Sidney Reweti Elliott',
					message: "Poi is and will always be a part of my life, Poi will always be known as part of who I am. Being a creator allows me to explore boundaries, concepts and patterns that usually don’t fit into the norms. Poi has no boundaries and each year I will continue discovering new and interesting gifts Poi unfolds to me every single time.",
					x: 176.7950132,
					y: -38.0471975,
					billboard : billboards.blue,
					bannerColor: 'blue',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Reweti_photo.jpg'
                    }
				},
				SteveeRayRaureti: {
					title: 'Stevee Ray Raureti',
					message: "I incorporate 12 years of intense gymnastics training, two years of jazz dancing and all my life of Kapa haka into my passion which is essentially poi. I would identify my style as Te Arawa/contemporary because I will throw a poi, do the splits and dance around a stage but still stand tall and proud whilst doing it.",
					x: 176.7526909,
					y: -37.8878685,
					billboard : billboards.blue,
					bannerColor: 'blue',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Stevee_photo.jpg'
                    }
				},
				HemaTeepa: {
					title: 'Hema Teepa',
					message: "Kei tēnā iwi, kei tēnā iwi, āna ake pakiwaitara, hei tiki atu, hei whakapuaki mai i te āhuatanga o tēnei taonga o te poi. Anei hei tauira ake māku, ko Hinepūkohurangi tēnei mē ōnā taina, te ātaahua o tōna hanga mai, i te kohu, ā, ka heke mai, ka hoki anō ki te rangi. I ēnei o ana haerenga i raro, ki runga, e tāea ai e koe kōriporipo i tō poi kotahi, e rua rānei, me te rere anō o tō tinana mē o waewae.",
					x: 176.9437399,
					y: -38.1639622,
					billboard : billboards.blue,
					bannerColor: 'blue',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Hema_photo.jpg'
                    }
				},
				ChrissyMoetara: {
					title: 'Chrissy Moetara',
					message: "From depicting the spreading growth of vines in ‘Poi Hinepūtehue’ carrying the qualities of ‘rangimarie’, to the characteristics of the pūkeko in ‘Poi Pūkeko’ carrying the qualities of care, of community, of caution and curiosity. Poi to me is the vehicle to remind us of those qualities that we have already inside of us and a means by which to draw them forward.",
					x: 177.9074439,
					y: -38.68014,
					billboard : billboards.blue,
					bannerColor: 'blue',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Chrissy_photo.jpg'
                    }
				},
				KarlLeonard: {
					title: 'Karl Leonard',
					message: "One of the most challenging taniko poi at its finest. Karl Leonard speaks about his poi entitled, ‘Poporo tū ki te hamutī – good things that come from bad’. It was woven during the controversy of the first male to be nominated onto Te Rōpū Raranga Whatu. His weaving had been commented on as ‘manly’ so his method of reply was to weave an extremely fine piece as a retort.",
					x: 176.336983,
					y: -38.114433, 
					billboard : billboards.red,
					bannerColor: 'red',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                            "qualitySources": [
					          {
					            "name": "1080p",
					            "sources": [
					              {"src": "https://player.vimeo.com/external/174321338.hd.mp4?s=f86f23da2142ce5964a963f2d0bf0349cee9d7ac&profile_id=119", "type": "video/mp4"}
					            ]
					          },
					          {
					            "name": "720p",
					            "sources": [
					              {"src": "https://player.vimeo.com/external/174321338.hd.mp4?s=f86f23da2142ce5964a963f2d0bf0349cee9d7ac&profile_id=174", "type": "video/mp4"}
					            ]
					          },
					          {
					            "name": "540p",
					            "sources": [
					              {"src": "https://player.vimeo.com/external/174321338.sd.mp4?s=9aa37f81e776e6aa88d47f936b5d4f8be24ce592&profile_id=165", "type": "video/mp4"}
					            ]
					          },
					          {
					            "name": "360p",
					            "sources": [
					              {"src": "https://player.vimeo.com/external/174321338.sd.mp4?s=9aa37f81e776e6aa88d47f936b5d4f8be24ce592&profile_id=164", "type": "video/mp4"}
					            ]
					          }
					        ]
                        }
                    }
				},
				NgamoniHuata: {
					title: 'Ngamoni Huata',
					message: "Ngamoni Huata is Aotearoa’s most published expert on Poi. She reflects on how the flourishing tourism industry of the early 1900’s bought about new and wanted opportunity for the indigenous people of Rotorua and expresses her views of how the younger generation are evolving poi as an artform.",
					x: 176.1644086,
					y: -38.1579267,
					billboard : billboards.red,
					bannerColor: 'red',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                            "qualitySources": [
                               {
                                    "name": "1080p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173048411.hd.mp4?s=be1d470327990eca18187aab4dd17fe579aabcd9&profile_id=119"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "720p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173048411.hd.mp4?s=be1d470327990eca18187aab4dd17fe579aabcd9&profile_id=174"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "540p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173048411.sd.mp4?s=267039c3738b82e3885987a8fc02a9ad5d298bb6&profile_id=165"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "360p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173048411.hd.mp4?s=be1d470327990eca18187aab4dd17fe579aabcd9&profile_id=119"), "type": "video/mp4"}
                                    ]
                                  }
                            ]
                        }
                    } 
				},
				PaketeWhero: {
					title: 'Pākete Whero',
					message: "This waiata poi has been famous at Whakarewarewa since the early 1900s. It is said that Guide Bella Papakura composed this song about her secret lover from Ruatāhuna. The wearing of the red scarves (pākete whero) by both of them signalled to each other their secret love. Performed at the Blue Lakes, Whakarewarewa by Lairelle Tamati, Miriata Ruaporo, Leilani Ngawhika, Kiriwaitingi Rei, Alaena Kiriwaitingi Rei & Ngairo Heretaunga.",
					x: 176.2543063,
					y: -38.161131,
					billboard : billboards.red,
					bannerColor: 'red',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                            "qualitySources": [
                               {
                                    "name": "1080p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174109973.hd.mp4?s=643cbfef422549024fc7fd3c1eed91bd341720d8&profile_id=119"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "720p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174109973.hd.mp4?s=643cbfef422549024fc7fd3c1eed91bd341720d8&profile_id=174"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "540p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174109973.sd.mp4?s=983ca7d738066dd4b4b977d1ba73be3a72a463f1&profile_id=165"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "360p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174109973.sd.mp4?s=983ca7d738066dd4b4b977d1ba73be3a72a463f1&profile_id=164"), "type": "video/mp4"}
                                    ]
                                  }
                            ]
                        }
                    } 
				},
				TePapaPoiAwe: {
					title: 'Te Papa - Poi Awe',
					message: "There are very few poi awe in Aotearoa and it was believed to be used for ceremonial purposes. Traditional poi awe were carefully made, demanding considerable skill on the part of the maker. It is recognisable by its unique patterns, weaving techniques and tassels of hair from the now extinct Polynesian kuri.",
					x: 174.687002,
					y: -41.297441,
					billboard : billboards.green,
					bannerColor: 'green',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                            qualitySources: [
                               {
                                    name: "1080p",
                                    sources: [{
                                    	src: $sce.trustAsResourceUrl("https://player.vimeo.com/external/173775176.hd.mp4?s=0d8d33fcac34a5040e5bb63eea0faabc0a5186be&profile_id=119"), 
                                     	type: "video/mp4"
                                  	}]
                                  },
                                  {
                                    name: "720p",
                                    sources: [{
                                    	src: $sce.trustAsResourceUrl("https://player.vimeo.com/external/173775176.hd.mp4?s=0d8d33fcac34a5040e5bb63eea0faabc0a5186be&profile_id=174"), 
                                    	type: "video/mp4"
                                    }]
                                  },
                                  {
                                    name: "540p",
                                    sources: [{
                                    	src: $sce.trustAsResourceUrl("https://player.vimeo.com/external/173775176.sd.mp4?s=82e85c60fc0b4bca6b99894a4934ae6d7d065eaa&profile_id=165"), 
                                    	type: "video/mp4"
                                    }]
                                  },
                                  {
                                    name: "360p",
                                    sources: [{
                                    	src: $sce.trustAsResourceUrl("https://player.vimeo.com/external/173775176.sd.mp4?s=82e85c60fc0b4bca6b99894a4934ae6d7d065eaa&profile_id=164"), 
                                    	type: "video/mp4"
                                    }]
                                  }
                            ]
                        }
                    } 
				},
				TePapaPoiTanikoPoiRaupo: {
					title: 'Te Papa - Poi Taniko & Poi Raupō',
					message: "Poi taniko has a woven exterior using a traditional weaving technique with materials like muka fibre prepared from flax. Today, it is a revitalised art form, reinvented by contemporary weavers. Poi raupō are known more as kinetic percussion instruments. Apart from the leaves being carefully formed to create the poi, the fluffy down of the raupō was also used as filling in some poi.",
					x: 174.827078,
					y: -41.297957,
					billboard : billboards.green,
					bannerColor: 'green',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                            "qualitySources": [
                               {
                                    "name": "1080p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/172608424.hd.mp4?s=e619e68c9293c45184ed9a9ffee8daff8b200f01&profile_id=119"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "720p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/172608424.hd.mp4?s=e619e68c9293c45184ed9a9ffee8daff8b200f01&profile_id=174"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "540p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/172608424.sd.mp4?s=e0d344a62e958ae16250d4d7eb707ae32f7a0e2a&profile_id=165"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "360p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/172608424.sd.mp4?s=e0d344a62e958ae16250d4d7eb707ae32f7a0e2a&profile_id=164"), "type": "video/mp4"}
                                    ]
                                  }
                            ]
                        }
                    } 
				},
				Takawirihanga: {
					title: 'The Story of the Song',
					message: "From the beautiful shore of Lake Okataina in Rotorua, comes the creation story of poi. Takawirihanga tells the story of Io the creator of the world and of the separation of Ranginui, the Sky Father and the Earth Mother, Papatūānuku by Tāne Mahuta, the guardian and father of the forests. From Tāne Mahuta came Harakeke and Raupō, and from them came the Poi. Songwriter Te Morehu Pīwai Tuhua and performer Kahurangi Maxwell explain their connection to the song and its significance in the world of poi.",
					x: 176.4172145,
					y: -38.1118631,
					billboard : billboards.blue,
					bannerColor: 'blue',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                            "qualitySources": [
                               {
                                    "name": "1080p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174078951.hd.mp4?s=af7741e6a666358294c191b6179d36116d3782ef&profile_id=174"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "720p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174078951.sd.mp4?s=6f1bf02991e35a210384b800e9370631c8d0d4d6&profile_id=165"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "540p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174078951.sd.mp4?s=6f1bf02991e35a210384b800e9370631c8d0d4d6&profile_id=164"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "360p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174078951.m3u8?s=4ac024776108cd3fb689db6d4f9f43b64c38aa64"), "type": "video/mp4"}
                                    ]
                                  }
                            ]
                        }
                    } 
				},
				TamakiMakaurau: {
					title: 'Tāmaki Makaurau (Auckland)',
					message: "Tāmaki Makaurau is the largest and most populated urban centre in New Zealand. With a population of almost 1.5 million, Tāmaki Makaurau hosts a diverse melting pot of ethnicities and cultures. Poi use in the region is predominantly seen in the context of the Auckland Regional Kapa Haka (Māori Performing Arts) competitions held bi-annually, from primary school level through to the adult competition. It is within this context we take a glimpse at the evolution of Poi as seen and experienced by some of its exponents, tutors and composers, both young and old. Featuring Ngā Tumanako and Ngā Puna o Waiorea.",
					x: 174.6292428,
					y: -36.9085909,
					billboard : billboards.orange,
					bannerColor: 'orange',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                            "qualitySources": [
                               {
                                    "name": "1080p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173998855.hd.mp4?s=a0cbf27b73ce8a52fc0e97a56821de5e11dcda49&profile_id=119"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "720p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173998855.hd.mp4?s=a0cbf27b73ce8a52fc0e97a56821de5e11dcda49&profile_id=174"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "540p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173998855.sd.mp4?s=f3b0c41074dbdb6ffd5016787dc19d25015ddfb6&profile_id=165"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "360p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173998855.sd.mp4?s=f3b0c41074dbdb6ffd5016787dc19d25015ddfb6&profile_id=164"), "type": "video/mp4"}
                                    ]
                                  }
                            ]
                        }
                    } 
				},
				KuiWheroBailey: {
					title: 'Whero o te Rangi Bailey',
					message: "Leader, teacher, weaver, conservationalist and treasured exponent of traditional Taranaki poi. A symbol of peace for mana whenua, poi became a recognisable feature in religion and passive resistance in the 1800s. Whero Bailey shares early memories of her experience with poi.",
					x: 173.8033217,
					y: -39.2865407,
					billboard : billboards.purple,
					bannerColor: 'purple',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                            "qualitySources": [
                               {
                                    "name": "1080p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173547077.hd.mp4?s=75b221e41bafd1a45c7d98930969a971ad098486&profile_id=119"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "720p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173547077.hd.mp4?s=75b221e41bafd1a45c7d98930969a971ad098486&profile_id=174"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "540p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173547077.sd.mp4?s=a0c48cf3052733e54b7104a9a1c56068f0062eff&profile_id=165"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "360p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173547077.sd.mp4?s=a0c48cf3052733e54b7104a9a1c56068f0062eff&profile_id=164"), "type": "video/mp4"}
                                    ]
                                  }
                            ]
                        }
                    } 
				},
				TeArawa: {
					title: 'Te Arawa',
					message: "The Te Arawa region has a contemporary poi history that dates back to the early 1900’s when tourism in the area began to flourish. It was said by the interviewees in this feature item that prior to this period, poi was used by men to increase the dexterity in their wrists for weaponry use during traditional warfare. However over the evolutionary period it has become common place in the homes and lives of many women and girls who hail from the subtribes of the Te Arawa region. All of the women we feature performing poi in this item, are the daughters and nieces of the exponents interviewed. It is within this context we take a glimpse at the evolution of Poi as seen and experienced by some of the tribal regions exponents, composers and historians.",
					x: 176.2167486,
					y: -38.0504106,
					billboard : billboards.red,
					bannerColor: 'red',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                            "qualitySources": [
                               {
                                    "name": "1080p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174145422.hd.mp4?s=ccc76bb4ac146d34192691927d5d95c29ccb7e15&profile_id=119"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "720p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174145422.hd.mp4?s=ccc76bb4ac146d34192691927d5d95c29ccb7e15&profile_id=174"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "540p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174145422.sd.mp4?s=9bdb8dc37adc17aebaa410754543b7a60b72c4d9&profile_id=165"), "type": "video/mp4"}
                                    ]
                                  },
                                  {
                                    "name": "360p",
                                    "sources": [
                                      {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174145422.sd.mp4?s=9bdb8dc37adc17aebaa410754543b7a60b72c4d9&profile_id=164"), "type": "video/mp4"}
                                    ]
                                  }
                            ]
                        }
                    } 
				},
				TePapa: {
					title: 'Te Papa Tongarewa Museum',
					message: "Te Papa has an array of poi that range from classical to contemporary, rare to revitalised and common to ceremonial. From poi awe to poi taniko to poi raupō, this treasure trove spans across centuries. Stories feature Puawai Cairns - Senior Curator of Māori Culture, Mark Sykes-Pōtae - Kaitiaki Collection of Māori Culture Manager and Awhina Tamarapa - ex-Curator with over 15 years knowledge on the poi collection.",
					x: 174.7786816,
					y: -41.2906428,
					billboard : billboards.green,
					bannerColor: 'green',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Te_Papa_photo.jpg'
                    }
				},
				PuawaiCairns: {
					title: 'Puawar Cairns',
					message: "Te Papa has an array of poi that range from classical to contemporary, rare to revitalised and common to ceremonial. From poi awe to poi taniko to poi raupō, this treasure trove spans across centuries and provides a wealth of knowledge and history. Because of their fragile state, many poi have never been exhibited making this a feature for some of these rare taonga.",
					x: 174.7786816,
					y: -41.2906428,
					billboard : billboards.green,
					bannerColor: 'green',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Puawai_photo.jpg'
                    }
				},
				AwhinaTamarapa: {
					title: 'Awhina Tamarapa',
					message: "Awhina Tamarapa was a Curator of Māori Culture at Te Papa for over 15 years. She specialises in Māori weaving and supports the revitalisation of taonga puoro and stone tool technology. Awhina possesses the most knowledge of the poi collection and speaks about the poi raupō and poi awe.",
					x: 174.7786816,
					y: -41.2906428,
					billboard : billboards.green,
					bannerColor: 'green',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Awhina_photo.jpg'
                    }
				},
				MarkSykes: {
					title: 'Mark Sykes',
					message: "Mark Sykes is the Kaitiaki Collection of Māori Culture Manager at Te Papa. He works with some of the Nation’s most important tāonga and also the mātauranga associated with this position. Mark speaks about the poi taniko collection.",
					x: 174.7786816,
					y: -41.2906428,
					billboard : billboards.green,
					bannerColor: 'green',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Mark_photo.jpg'
                    }
				},
				KaporangiKiriata: {
					title: 'Huia Kaporangi Koziol',
					message: "Screening at the Gaiety Theatre in Wairoa, Kaporangi Kiriata is in recognition of Huia’s middle name meaning 'grab for the stars'. This wonderful archive was part of a tribute to the late Huia Koziol – a māmā, nana, teacher, councilor and Wairoa Māori Film Festival Co-Chairperson. The 16mm film was recorded at a private party in Beverly Hills, Los Angeles, in 1959. Huia was part of the Kia Ora Club, based out of BYU in Utah. Other performers are June Cotter and Rona Belcher. We thank her son Leo Koziol and whanau for allowing us to include this tribute.",
					x: 177.7384851,
					y: -39.0456949,
					billboard : billboards.yellow,
					bannerColor: 'yellow',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                                "qualitySources": [
                                   {
                                        "name": "1080p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173342271.hd.mp4?s=c2ca3487ed1d659cde68e445ab9ab57646f2da8b&profile_id=174"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "720p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173342271.hd.mp4?s=c2ca3487ed1d659cde68e445ab9ab57646f2da8b&profile_id=174"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "540p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173342271.sd.mp4?s=d7be4aea6d7f50b7492df4f2a59d964b464ccc58&profile_id=165"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "360p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173342271.sd.mp4?s=d7be4aea6d7f50b7492df4f2a59d964b464ccc58&profile_id=164"), "type": "video/mp4"}
                                        ]
                                      }
                                ]
                        }
                    }
				},
				Taranaki: {
					title: 'Taranaki',
					message: "The distinct style of Poi in the region is unique to its tribal area. For generations, Taranaki Poi has been performed as a vehicle for genealogical and historical storytelling. There is a particular emphasis placed on the remembrance of the painful history relative to land loss in the area, around the mid to late 1800’s. This is the context in which we profile the regions poi history and how it lives on in the descendants of those poi use originators though to this day.",
					x: 174.0634,
					y: -39.2968,
					billboard : billboards.purple,
					bannerColor: 'purple',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                        type: 'video',
                        config: {
                                "qualitySources": [
                                   {
                                        "name": "1080p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174146455.hd.mp4?s=4d1241ba2fbbf7b8ee706d0fa72220fc245e81a3&profile_id=119"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "720p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174146455.hd.mp4?s=4d1241ba2fbbf7b8ee706d0fa72220fc245e81a3&profile_id=174"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "540p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174146455.sd.mp4?s=638f56a5da538e19693464bb3587fd213166652b&profile_id=165"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "360p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174146455.sd.mp4?s=638f56a5da538e19693464bb3587fd213166652b&profile_id=164"), "type": "video/mp4"}
                                        ]
                                      }
                                ]
                        }
                    }
				},
				PoiE: {
					title: 'Poi E',
					message: "The Story of the Song. ‘Poi E’, a simple song with a catchy beat released 32 years ago, has become New Zealand’s unofficial national anthem. With humour, energy and emotion, the movie POI E is the story of how that iconic song gave pride to generations of New Zealanders. From Taika Waititi giving Stan Walker his quirky take on life in the 1980s to Patea Maori Club members’ straight-talking and funny memories of the song’s visionary originator Dalvanius Prime, director Tearepa Kahi (Mt Zion) captures a unique story that taps into the heart of the nation. <a href='http://www.poiemovie.co.nz' target='_blank'>www.poiemovie.co.nz</a>",
					x: 174.4436931,
					y: -39.7004386,
					billboard : billboards.purple,
					bannerColor: 'purple',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/POI-E-thumb.jpg'
                    }
				},
				MaoriTelevision: {
					title: 'Māori Television',
					message: "<a href='https://www.maoritelevision.com/tv/shows/poi360' target='_blank'>Māori Television</a> is the indigenous television station and digital on-demand platform in Aotearoa, NZ. As the home of haka, it holds links to various programmes and live performances that feature poi.",
					x: 174.778441,
					y: -36.864988,
					billboard : billboards.orange,
					bannerColor: 'orange',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/maori-television-thumb.jpg'
                    }
				},
				TeArohaPaenga: {
					title: 'Te Aroha Paenga',
					message: "Ko Ngāti Porou, Ko Ngāti Kahungunu ngā iwi. Te Aroha Paenga grew up in her grandparents home on the east coast of the North Island in a small settlement called Whangara. She is the co-founder and tutor of well established West Auckland based Kapa Haka group, Te Rōpū Manutaki. She has since judged Poi at senior level national competitions for over 15 years.",
					x: 174.7973273,
					y: -36.967003,
					billboard : billboards.orange,
					bannerColor: 'orange',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{ 
                        type: 'video',
                        config: {
                                "qualitySources": [
                                   {
                                        "name": "1080p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173997604.hd.mp4?s=a8b3b02c2ce1e9aa8444d8119bbdb5cb2e4e39b4&profile_id=119"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "720p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173997604.hd.mp4?s=a8b3b02c2ce1e9aa8444d8119bbdb5cb2e4e39b4&profile_id=174"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "540p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173997604.sd.mp4?s=7701737458687a60d83eb20c2c6163467cd3f7ff&profile_id=165"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "360p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173997604.sd.mp4?s=7701737458687a60d83eb20c2c6163467cd3f7ff&profile_id=164"), "type": "video/mp4"}
                                        ]
                                      }
                                ]
                        }
                    }
				},
				Tareikura: {
					title: 'Tāreikura',
					message: "<a href='https://www.facebook.com/T%C4%81reikura-1435111770134811' target='_blank'>Tāreikura</a> is a Māori Arts Grooming Academy comprised of 10 Māori speaking women aged eight to eighteen, who hail predominantly from the more central tribes of the North Island. They come together weekly to hone their specialist skills in Māori performing arts and Māori weaponry, with the guidance of some of the countries most experienced exponents. Poi is a prominent feature in their training as they believe it is a great strengthening tool for the mind, body and soul. Here we see a snippet of one of their more contemporary adaptions of poi with modern music.",
					x: 175.744582,
					y: -38.944036,
					billboard : billboards.orange,
					bannerColor: 'orange',
					heading: randomHeading(), 
					pitch: cityPitch,
					range: cityRange,
					media:{
                    	type: 'photo',
                    	url: 'images/map/Tareikura_photo.jpg'
                    }
				}
			};

			$scope.entities = {
				TeArawa: viewer.entities.add(viewEntity('TeArawa')),
				Taranaki: viewer.entities.add(viewEntity('Taranaki')),
				TamakiMakaurau: viewer.entities.add(viewEntity('TamakiMakaurau')),
				Takawirihanga: viewer.entities.add(viewEntity('Takawirihanga')),
				TePapaPoiTanikoPoiRaupo: viewer.entities.add(viewEntity('TePapaPoiTanikoPoiRaupo')),
				TePapaPoiAwe: viewer.entities.add(viewEntity('TePapaPoiAwe')),
				NgamoniHuata: viewer.entities.add(viewEntity('NgamoniHuata')),
				KuiWheroBailey: viewer.entities.add(viewEntity('KuiWheroBailey')),
				TeArohaPaenga: viewer.entities.add(viewEntity('TeArohaPaenga')),
				KaporangiKiriata: viewer.entities.add(viewEntity('KaporangiKiriata')),
				PoiE: viewer.entities.add(viewEntity('PoiE')),
				WikitoriaTuhakarainaSimon: viewer.entities.add(viewEntity('WikitoriaTuhakarainaSimon')),
				PiataWaitai: viewer.entities.add(viewEntity('PiataWaitai')),
				ChrissyMoetara: viewer.entities.add(viewEntity('ChrissyMoetara')),
				SidneyRewetiElliott: viewer.entities.add(viewEntity('SidneyRewetiElliott')),
				HemaTeepa: viewer.entities.add(viewEntity('HemaTeepa')),
				MahiaGreen: viewer.entities.add(viewEntity('MahiaGreen')),
				SteveeRayRaureti: viewer.entities.add(viewEntity('SteveeRayRaureti')),
				TaniaKiel: viewer.entities.add(viewEntity('TaniaKiel')),
				KimikimiMane: viewer.entities.add(viewEntity('KimikimiMane')),
				Tareikura: viewer.entities.add(viewEntity('Tareikura')),
				MaoriTelevision: viewer.entities.add(viewEntity('MaoriTelevision')),
				TePapa: viewer.entities.add(viewEntity('TePapa')),
				// KarlLeonard: viewer.entities.add(viewEntity('KarlLeonard')),
				// PuawaiCairns: viewer.entities.add(viewEntity('PuawaiCairns')),
				// AwhinaTamarapa: viewer.entities.add(viewEntity('AwhinaTamarapa')),
				// MarkSykes: viewer.entities.add(viewEntity('MarkSykes')),
				// PaketeWhero: viewer.entities.add(viewEntity('PaketeWhero')),
			}

			var defaultEntityForCoords = 'TePapa';

			$scope.openMenu = function() {

				if ($(window).width() <= 768) {
					angular.element('.explore-map__nav').addClass('mobile-menu-active');
					var mapNav = angular.element('.explore-map__nav');
					var tl = new TimelineMax();
					tl.from(mapNav, .25, {autoAlpha: 0});
					tl.to(mapNav, .5, {autoAlpha: 1});
				}
			}

			$scope.closeMenu = function() {

				if ($(window).width() <= 768) {
					var mapNav = angular.element('.explore-map__nav');
					var tl = new TimelineMax();
					tl.to(mapNav, .5, {autoAlpha: 0, className:"-=mobile-menu-active"});
				}
				
			}


			function randomHeading(){
				var random = Math.random();
				var deg = random * 100;

				if(Math.random() >= 0.5){
					deg = -deg;		
				}

				return Cesium.Math.toRadians(deg);
			}
 
			function viewEntity(city){
				return {
				  position : Cesium.Cartesian3.fromDegrees(locations[city].x, locations[city].y),
				  billboard : {
				      image : locations[city].billboard.image,
				      width : locations[city].billboard.width / 1.25,
				      height : locations[city].billboard.height / 1.25
				  },
				 //  label : {
					// text : locations[city].title.toUpperCase(),
				 //    font : '20px',
				 //    verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
				 //    pixelOffset : new Cesium.Cartesian2(0, -48)
				 //  },
				  heading: locations[city].heading, 
				  pitch: locations[city].pitch, 
				  range: locations[city].range,
				  title: locations[city].title,
				  message: locations[city].message,
				  media: locations[city].media,
				  slug: city,
				  bannerColor: locations[city].bannerColor
				};
			}

			function traceCoordinates(){
				viewer.canvas.addEventListener('click', function(e){
				    var mousePosition = new Cesium.Cartesian2(e.clientX, e.clientY);

				    var ellipsoid = viewer.scene.globe.ellipsoid;
				    var cartesian = viewer.camera.pickEllipsoid(mousePosition, ellipsoid);
				    if (cartesian) {
				        var cartographic = ellipsoid.cartesianToCartographic(cartesian);
				        var longitudeString = Cesium.Math.toDegrees(cartographic.longitude).toFixed(2);
				        var latitudeString = Cesium.Math.toDegrees(cartographic.latitude).toFixed(2);
				    } else {
				        // console.log('Globe was not picked');
				    }
					
				}, false);
			}

			function drillPickEntities(windowPosition) {
			  var i;
			  var entity;
			  var picked;
			  var pickedPrimitives = scene.drillPick(windowPosition);
			  var length = pickedPrimitives.length;
			  var result = [];
			  var hash = {};

			  for (i = 0; i < length; i++) {
			    picked = pickedPrimitives[i];
			    entity = Cesium.defaultValue(picked.id, picked.primitive.id);
			    if (entity instanceof Cesium.Entity &&
			        !Cesium.defined(hash[entity.id])) {
			      result.push(entity);
			      hash[entity.id] = true;
			    }
			  }
			  return result;
			};


			function flyToCity(city){
				var camera = scene.camera;
			    camera.flyTo({
			        destination : Cesium.Cartesian3.fromDegrees(locations[city].x, locations[city].y),
			        orientation : {
			        	// direction : Cesium.Cartesian3.negate(Cesium.Cartesian3.UNIT_Z, new Cesium.Cartesian3())
        				// up : new Cesium.Cartesian3(-0.47934589305293746, -0.8553216253114552, 0.1966022179118339)
	                    heading : locations[city].heading,
	                    pitch : locations[city].pitch
	                }
	            });
			}

			function setView(city){
				var camera = scene.camera;

				camera.setView({
					position : new Cesium.Cartesian3.fromDegrees(locations[city].x, locations[city].y),
				    heading : locations[city].heading,
				    pitch : locations[city].pitch
				});
			}

			function lookAt(city){
				var center = Cesium.Cartesian3.fromDegrees(locations[city].x);
				var heading = locations[city].heading;
				var pitch = locations[city].pitch;
				var range = locations[city].z;
				viewer.camera.lookAt(center, new Cesium.HeadingPitchRange(heading, pitch, range));
			}

			function flyToDefault(entity, fly){
				var promise;
				var offset = new Cesium.HeadingPitchRange(locations.default.heading, locations.default.pitch, locations.default.range);

				inFlight = inFlight + 1;

				if(fly){
					promise = viewer.flyTo(entity, {
						offset: offset,
						maximumHeight: cityRange * 2
					});
				}else{
					promise = viewer.zoomTo(entity, offset);
				}
				
				promise.then(function(response) {
					inFlight = inFlight - 1;
					
					if(fly & inFlight === 0){
						showWelcomeTooltip();
					}
				});
			}

			$animate.on('leave', $('.loading-screen'), function(element) {
		    	if(!element.hasClass('ng-animate')){
					showWelcomeTooltip();		    		
		    	}
		  	});

		  	function showWelcomeTooltip(){
		  		$scope.welcomeTooltip = true;
				$scope.$apply();
		  	}

			function flyToEntity(entity){
				inFlight = inFlight + 1;
				$scope.activeEntity = entity;
				// $scope.$apply();

				var promise = viewer.flyTo(entity, {
					offset: new Cesium.HeadingPitchRange(entity.heading, entity.pitch, entity.range),
					maximumHeight: cityRange * 1
				});

				promise.then(function(response) {
					inFlight = inFlight - 1;

					if(inFlight === 0){
						flyToCallback(entity);
					}
				  	
				});
			}


			$scope.mapNavClick = function(city){
				$scope.welcomeTooltip = false;
				$scope.flyToLocation(city, true);	

				if(city == 'default'){

				}			
			}

			$scope.flyToLocation = function(city, fly){		
				var entity = $scope.entities[city];
				$scope.modal.open = false;
				$scope.modal.showButton = false;
				
				if(city == 'default'){
					$scope.activeEntity = null;
					entity = $scope.entities[defaultEntityForCoords];
					flyToDefault(entity, fly);
				}else{
					flyToEntity(entity);
				}
			}

			function flyToCallback(entity){
				if(entity.title){
					if(!isMobile){
						$scope.modal.open = true;	
						soundFactory.stopLoop('background');
					}else{
						$scope.modal.showButton = true;
					}
					$scope.modal.title = entity.title;
					$scope.modal.message = entity.message;
					$scope.modal.description = entity.message;
					$scope.modal.media = entity.media;
					if(entity.media.type === 'video'){
						$scope.modal.media.config.currentQualitySource = entity.media.config.qualitySources[1];
					}
					$scope.modal.bannerColor = entity.bannerColor;
					$scope.modal.sharePage = {
						url: '/explore-aotearoa/'+entity.slug
					};
					$scope.$apply();
				}
			}

			if($scope.story){
				$scope.welcomeTooltip = false;
				$scope.flyToLocation($scope.story, true);
			}else{
				$scope.flyToLocation('default', false);	
			}
			
			// introScene();
			// traceCoordinates();
	    }]
	};
}])

.directive('mapNav', [function(){
	return {
		templateUrl: "scripts/app/components/explore-map/explore-map-nav.html",
		link: function(scope, element, attrs){
		}
	};
}])

.directive('welcomeTooltip', [function(){
	return {
		templateUrl: "scripts/app/components/explore-map/explore-map-welcome-tooltip.html",
		link: function(scope, element, attrs){
		}
	};
}])

.directive('clouds', [function(){
	return {
		template: '<div class="explore-map__clouds" ng-if="welcomeTooltip"><div class="explore-map__cloud explore-map__cloud-1"></div><div class="explore-map__cloud explore-map__cloud-2"></div></div>',
		link: function(scope, element, attrs){
		}
	};
}])
;
