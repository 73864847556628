'use strict';

angular.module('poi360.components.interactive-video.interactive-video-services', [])

.factory('InteractiveVideoOverlayService', function(){
    var state = {
        open: false
    };

    return {
        getState: function() { return state; }
    };
});