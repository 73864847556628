'use strict';

angular.module('poi360.views.poi-the-heartbeat-of-a-nation', [
	'ngRoute',
])

.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('poi-the-heartbeat-of-a-nation', {
        url: "/poi-the-heartbeat-of-a-nation",
        templateUrl: 'scripts/app/views/poi-the-heartbeat-of-a-nation/poi-the-heartbeat-of-a-nation.html',
	    controller: 'StoryOfCreationCtrl',
	    controllerAs: "ctrl",
	    loadItems: 3,
	    resolve: {
	    	pageWait: ['$q', '$timeout', 'animationSettings', function($q, $timeout, animationSettings){
	             var deferred = $q.defer();
	             $timeout(function() {
                    deferred.resolve();
	             }, animationSettings.loadingPage.stayOpen);
	             return deferred.promise;
	        }],
            preloadImages: ['preloader', 'progressBar', function(preloader, progressBar){
                var imageLocations = [
                    "svgs/poi-diamond-icon.svg",
                    "svgs/poi-diamond-icon-blue.svg"
                ];

                // Preload the images
                return preloader.preloadImages(imageLocations).then(
                    function(imageLocations){
                        //RESOLVE
                        return imageLocations;
                    },
                    function(imageLocation){
                        //REJECT
                        return imageLocation;
                    },
                    function(event){
                        // NOTIFY
                    progressBar.itemComplete();
                    }
                );
            }]
	    },
		onEnter: ["progressBar", function(progressBar){
			var progressComplete = progressBar.complete();	
		}],
    });
}])

.controller('StoryOfCreationCtrl', ['$sce', '$animate', 'appHeaderFactory', '$timeout', '$scope', 'animationSettings', function($sce, $animate, appHeaderFactory, $timeout, $scope, animationSettings){

	// $animate.on('leave', $('.loading-screen'), function(element) {
 //    	if(!element.hasClass('ng-animate')){
 //    		animIn();
 //    	}
 //  	});

  	function animIn(){
		var $page = $('.soc');

		var textDuration = 0.5;
		var textEase = Power2.easeIn;

		var revealTl = new TimelineMax();
		revealTl.from($page, textDuration, {opacity: 0, ease: textEase, onComplete: animComplete}, "+="+animationSettings.loadingPage.speedOut);
	}

	var animComplete = function(){
		appHeaderFactory.showHeader(true);
	}

    animIn();

	this.poiCuePointParams = {};
	this.currentCuePoint = {};
	this.overlayOpen = false;
    this.API = null;
    this.overlayOpen = false;

    $scope.subtitleHover = false;
    $scope.selectedSubtitle = null;

    var subtitlesMouseLeaveTimeout;

    this.onPlayerReady = function (API) {
        this.API = API;
        // this.API.play();
    };

    // POI Cue Points
    this.onPoiCuePointEnter = function onPoiCuePointEnter(currentTime, timeLapse, params) {

    };

    this.onPoiCuePointLeave = function onPoiCuePointLeave(currentTime, timeLapse, params) {
        this.poiCuePointParams = null;
        this.currentCuePoint = null;
    };

    this.onPoiCuePointUpdate = function onPoiCuePointUpdate(currentTime, timeLapse, params) {
        this.poiCuePointParams = params;
        this.currentCuePoint = this.config.cuePoints.poi[params.index];
    };

    this.onPoiCuePointComplete = function onPoiCuePointComplete(currentTime, timeLapse, params) {
        this.poiCuePointParams = null
        this.currentCuePoint = null;
    };

    this.toggleSubtitles = function(track){
        this.config.tracks[0].default = false;
        this.config.tracks[1].default = false;

        if($scope.selectedSubtitle == track){
            $scope.selectedSubtitle = null;
            this.config.tracks[track].default = false;
        }else{
            $scope.selectedSubtitle = track;
            this.config.tracks[track].default = true;
        }

	    return false;
	};

    this.subtitlesMouseOver = function(){
        $timeout.cancel(subtitlesMouseLeaveTimeout);
        $scope.subtitleHover = true;
    }

    this.subtitlesMouseLeave = function(){
        $timeout.cancel(subtitlesMouseLeaveTimeout);

        subtitlesMouseLeaveTimeout = $timeout(function(){
            $scope.subtitleHover = false;
        }, 600);
    }

	this.closeOverlay = function(){
		this.overlayOpen = false;
		this.API.play();
	}

    this.config = {
        qualitySources: [
          {
            name: "1080p",
            sources: [
              {src: "https://player.vimeo.com/external/174095799.hd.mp4?s=599dc91b46f3fb095da53200a0c4ab042c76cbbd&profile_id=119", type: "video/mp4"}
            ]
          },
          {
            name: "720p",
            sources: [
              {src: "https://player.vimeo.com/external/174095799.hd.mp4?s=599dc91b46f3fb095da53200a0c4ab042c76cbbd&profile_id=174", type: "video/mp4"}
            ]
          },
          {
            name: "540p",
            sources: [
              {src: "https://player.vimeo.com/external/174095799.sd.mp4?s=1d4df5c837a4c355107eb8c0b77bf6bed80d8696&profile_id=165", type: "video/mp4"}
            ]
          },
          {
            name: "360p",
            sources: [
              {src: "https://player.vimeo.com/external/174095799.sd.mp4?s=1d4df5c837a4c355107eb8c0b77bf6bed80d8696&profile_id=164", type: "video/mp4"}
            ]
          }
        ],
        tracks: [
            {
                // src: "/subs/POI_360_Doco_captions_eng_WebVTT.vtt",
                src: "/subs/poi-english-subtitles.vtt",
                kind: "subtitles",
                srclang: "en",
                label: "English",
                default: false
            },
            {
                // src: "/subs/POI_360_Doco_captions_mi_WebVTT.vtt",
                src: "/subs/poi-maori-subtitles.vtt",
                kind: "subtitles",
                srclang: "mi",
                label: "Maori",
                default: false
            }
        ],
        cuePoints: {
            poi: [
                {
                    timeLapse: {
                        start: 0,
                        end: 10
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                        index: 0,
                        title: "",
                        message: "",
                        position: {
                            top: '-100%',
                            left: '-100%'
                        },
                        media:{
                            type: 'photo'
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 60,
                        end: 70
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                    	index: 1,
                    	title: "Poi",
                      	message: "'Poi' refers to the ball on the end of a string and means ‘to toss’ or ‘to swing’. The ball is said to represent the head of Tāne Mahuta (God of the Forest) and the cord, his feet. This is in reference to the origin story where Tāne Mahuta used his great strength to separate Ranginui, the Sky Father, and Papatūānuku, the Earth Mother. This action brought light into the world and the creation of many elements, including Poi. ",
                        position: {
                        	top: '20%',
                        	left: '30%'
                        },
                        media:{
                        	type: 'photo',
                        	url: 'images/doco/single-long-poi-640x360.jpg'
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 98,
                        end: 108
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                    	index: 2,
                    	title: "Traditional House of Poi",
                        message: "Poi, as both a dance and a game, is seen as an item that belongs in the whare tāpere (house of entertainment) and the patronage of Hineraukatauri and Hineraukatamea. These deities are of the Hawaiki era and origin of all games and dances. Poi fits into all six parts of the whare tāpere; ngā haka (dance), ngā taonga pūoro (musical instruments), ngā waiata (songs), ngā tākaro (games and amusements), ngā momo korero (storytelling) and ngā taonga o wharawhara (personal adornments).",
                        position: {
                        	top: '50%',
                        	left: '30%'
                        },
                        media:{
                        	type: 'photo',
                        	url: 'images/doco/pattern-640x360.jpg'
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 195,
                        end: 205
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                        index: 3,
                        title: "Taranaki Poi",
                        message: "The distinct style of Poi in the Taranaki (New Plymouth and surrounding area) region is unique to its tribal area. For generations, Taranaki Poi has been performed as a vehicle for genealogical and historical storytelling. There is a particular emphasis placed on the remembrance of the painful history relative to land loss in the area, around the mid to late 1800’s. Poi - a symbol of peace and change for tangata whenua surviving land wars and confiscation by early settlers. Poi became one of the most recognisable aspects in the religious teachings of two nineteenth century prophets, Te Whiti-o-Rongomai and Tohu Kākahi.",
                        position: {
                            top: '50%',
                            left: '30%'
                        },
                        media:{
                            type: 'photo',
                            url: 'images/doco/taranaki-poi.jpg'
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 250,
                        end: 267
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                        index: 4,
                        title: "Poi in Passive Resistance",
                        message: "Poi - a symbol of peace for mana whenua of Taranaki surviving land wars and confiscation by early settlers in the 1800’s. Poi became one of the most recognisable aspects in the religious teachings of two nineteenth century prophets, Te Whiti-o-Rongomai and Tohu Kākahi. Te Whiti and Tohu frequently employed the various types of poi, such as poipoi whakapapa, poi manu, poi karaipiture, poi kawa, poi karakia and poi matakite, during their time at Parihaka.",
                        position: {
                            top: '60%',
                            left: '40%'
                        },
                        media:{
                            type: 'photo',
                            url: 'images/doco/Poi-In-Passive-Resistance.jpg'
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 470,
                        end: 480
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                        index: 5,
                        title: "Whero O Te Rangi Bailey",
                        message: "Taranaki Whānui, Te Ati Awa, Ngāti Maniapoto, Ngāti Raukawa, Ngāi Tahu. Leader, teacher, weaver, conservationalist and treasured exponent of traditional Taranaki poi. A symbol of peace for mana whenua, poi became a recognisable aspect in religion and passive resistance in the 1800s. Whero Bailey shares early memories of her experience with poi.",
                        position: {
                            top: '20%',
                            left: '60%'
                        },
                        media:{
                            type: 'video',
                            config: {
                                "qualitySources": [
                                   {
                                        "name": "1080p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173547077.hd.mp4?s=75b221e41bafd1a45c7d98930969a971ad098486&profile_id=119"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "720p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173547077.hd.mp4?s=75b221e41bafd1a45c7d98930969a971ad098486&profile_id=174"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "540p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173547077.sd.mp4?s=a0c48cf3052733e54b7104a9a1c56068f0062eff&profile_id=165"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "360p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/173547077.sd.mp4?s=a0c48cf3052733e54b7104a9a1c56068f0062eff&profile_id=164"), "type": "video/mp4"}
                                        ]
                                      }
                                ]
                            }
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 600,
                        end: 612
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                        index: 6,
                        title: "Te Arawa Poi",
                        message: "Te Arawa (Rotorua region) has a contemporary poi history that dates back to the early 1900’s when tourism in the area began to flourish. It was said that prior to this period, poi was used by men to increase the dexterity in their wrists for weaponry use during traditional warfare. However, over the evolutionary period it has become common place in the homes and lives of many women and girls who hail from the subtribes of the Te Arawa region. All of the women we feature performing poi in this item, are the daughters and nieces of the exponents interviewed.",
                        position: {
                            top: '60%',
                            left: '40%'
                        },
                        media:{
                            type: 'photo',
                            url: 'images/doco/Te-Arawa-Poi.jpg'
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 684,
                        end: 697
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                        index: 7,
                        title: "Poi Raupō",
                        message: "Poi raupō are known as kinetic percussion instruments, popular for performance in the early 1900s. The leaves were carefully formed to create the poi and the fluffy down of the raupō was also used as filling in some poi. www.tepapa.govt.nz",
                        position: {
                            top: '60%',
                            left: '55%'
                        },
                        media:{
                            type: 'photo',
                            url: 'images/doco/poi-raupo-640x360.jpg'
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 941,
                        end: 951
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                        index: 8,
                        title: "Pākete Whero",
                        message: "This waiata poi has been famous at Whakarewarewa since the early 1900s and continues to be performed today. It is said that Guide Bella Papakura composed this song about her secret lover from Ruatāhuna. The wearing of the red scarves (pākete whero) signalled to each other their secret love. This well-known poi blends aspects of Māori composition with European techniques. The combination of innuendos hinting of a secret love, so often a part of waiata, and the fast paced poi actions based on galloping horses, is a typical example of the modernisation of poi in the Te Arawa area unseen prior to the early 1900s.",
                        position: {
                            top: '50%',
                            left: '30%'
                        },
                        media:{
                            type: 'video',
                            config: {
                                "qualitySources": [
                                   {
                                        "name": "1080p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174109973.hd.mp4?s=643cbfef422549024fc7fd3c1eed91bd341720d8&profile_id=119"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "720p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174109973.hd.mp4?s=643cbfef422549024fc7fd3c1eed91bd341720d8&profile_id=174"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "540p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174109973.sd.mp4?s=983ca7d738066dd4b4b977d1ba73be3a72a463f1&profile_id=165"), "type": "video/mp4"}
                                        ]
                                      },
                                      {
                                        "name": "360p",
                                        "sources": [
                                          {"src": $sce.trustAsResourceUrl("https://player.vimeo.com/external/174109973.sd.mp4?s=983ca7d738066dd4b4b977d1ba73be3a72a463f1&profile_id=164"), "type": "video/mp4"}
                                        ]
                                      }
                                ]
                            }
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 1023,
                        end: 1028
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                        index: 9,
                        title: "Tāmaki Poi",
                        message: "Tāmaki Makaurau (Auckland) is the largest and most populated urban centre in New Zealand. With a population of almost 1.5 million, Tāmaki Makaurau hosts a diverse melting pot of ethnicities and cultures. Poi use in the region is predominantly seen in the context of the Auckland Regional Kapa Haka (Māori Performing Arts) competitions held bi-annually, from primary school level through to the adult competition.",
                        position: {
                            top: '60%',
                            left: '70%'
                        },
                        media:{
                            type: 'photo',
                            url: 'images/doco/tamaki-poi.jpg'
                        }
                    }
                },
                {
                    timeLapse: {
                        start: 1247,
                        end: 1257
                    },
                    onEnter: this.onPoiCuePointUpdate.bind(this),
                    onLeave: this.onPoiCuePointLeave.bind(this),
                    onUpdate: this.onPoiCuePointUpdate.bind(this),
                    onComplete: this.onPoiCuePointComplete.bind(this),
                    params: {
                        index: 10,
                        title: "Poi Today",
                        message: "Poi has grown and become an international item of entertainment used by both Māori and non-Māori. Websites can be found dedicated to poi, with all acknowledging its genesis from Te Iwi Māori. Fire poi, fabric tailed poi, fluffy poi with chain cords, and poi balls made from high impact resistant plastic with LED lights to create a stunning visual effect, are some of the innovations in poi that have occurred. In this way the opening lines of Erenora Taratoa’s famous pātere “Poia atu taku poi, wania atu taku poi - Swing afar my poi, skim onward my poi” still rings true. Source: Poia mai taku poi – K.Paringatai",
                        position: {
                            top: '35%',
                            left: '25%'
                        },
                        media:{
                            type: 'photo',
                            url: 'images/doco/poi-today.jpg'
                        }
                    }
                }
            ]
        },
        plugins: {
            poster: {
                url: "assets/images/videogular.png"
            }
        }
    };

    $.each(this.config.cuePoints.poi, function(){
        if(this.params.media.type === "video"){
            this.params.media.config.currentQualitySource = this.params.media.config.qualitySources[1]; 
        }
    });

    this.config.currentQualitySource = this.config.qualitySources[1];

}]);