'use strict';

angular.module('poi360.views.legal', [])

.config(['$stateProvider', function($stateProvider) {

	$stateProvider.state('legal', {
        url: "/legal",
        templateUrl: 'scripts/app/views/legal/legal.html',
        controller: 'legalCtrl',
		onEnter: ["progressBar", function(progressBar){
			var progressComplete = progressBar.complete();
		}]
    });
}])

.controller('legalCtrl', ['$scope', '$animate', 'appHeaderFactory', 'animationSettings', function($scope, $animate, appHeaderFactory, animationSettings){

	animIn();

	function animIn(){
		var $page = $('.legal');

		var revealTl = new TimelineMax();
		
		revealTl.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease, onComplete: animComplete});
	}
	
	function animComplete(){
		appHeaderFactory.showHeader(true);

		var revealTl = new TimelineMax();
		
		revealTl.to($('.extended-footer'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease});
	}
}]);