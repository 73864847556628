'use strict';

angular.module('poi360.components.helpers', [])

.directive('fadeIn', [function(){
    return {
        restrict: 'A',
        link: function($scope, $element, attrs){
            $element.on('load', function() {
                $element.addClass("ng-hide-add");
            });
        }
    };
}])

.directive('bgPattern', [function(){
    return {
        template: '<div class="bg-pattern__wrapper"><img class="animate-show" src="/images/pattern-bg.png" fade-in></div>'
    };
}])

.directive('bgClouds', [function(){
    return {
        template: '<div class="bg-clouds__wrapper"><img class="animate-show" src="/images/cloud-32.png" fade-in></div>'
    };
}])

.directive('bgVideo', [function(){
	return {
		scope: {
			src: "="
		},
		template: '<video loop muted autoplay class="homepage__bg-video"><source ng-src="{{trustSrc(src)}}" type="video/mp4"></video>',
		controller: ['$scope', '$sce', function($scope, $sce){
			$scope.trustSrc = function(src) {
				return $sce.trustAsResourceUrl(src);
			}
		}]
	};
}]);