'use strict';

angular.module('poi360.components.volume-bar.volume-bar', [])

.directive('appVolumeBar', [function(){
	return {
		templateUrl: "scripts/app/components/volume-bar/volume-bar.html",
		controller: ['$scope', 'soundFactory', function($scope, soundFactory){
			$scope.showVolume = false;
			$scope.muted = false;

			$scope.onClickMute = function(){
				if(soundFactory.muted){
					//unmute
					soundFactory.masterMute(false);	
					$scope.muted = false;
				}else{
					//mute
					soundFactory.masterMute(true);
					$scope.muted = true;
				}
			}

			$scope.$watch(function(){return soundFactory.visible}, function(NewValue, OldValue){
				$scope.showVolume = soundFactory.visible;
			});
		}]
	};
}]);