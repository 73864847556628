'use strict';

angular.module('poi360.components.meta-tags', [])

.directive('metaTags', [function(){
	return {
		scope: {
			pageMeta: "="
		},
		templateUrl: "scripts/app/components/meta-tags/meta-tags.html",
		link: function(scope, element, attrs){
		}
	}
}]);