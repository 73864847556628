'use strict';

angular.module('poi360.components.sound-effect.sound-effect', [])

.directive('soundEffect', [function(){
	return {
		scope:{
			clickSound: "@",
			hoverSound: "@"
		},
		controller: ['$scope', 'soundFactory', 'mobileDetection', function($scope, soundFactory, mobileDetection){
			var isMobile = mobileDetection.isMobile();

			if(!isMobile) soundFactory.load('click');
			if(!isMobile) soundFactory.load('hover');

			this.clickEffect = function(){
				if($scope.clickSound){
					if(!isMobile) soundFactory.play($scope.clickSound);	
				}
			}

			this.hoverEffect = function(){
				if($scope.hoverSound){
					if(!isMobile) soundFactory.play($scope.hoverSound);
				}
			}
		}],
		link: function(scope, elem, attrs, ctrl) {
			elem.bind('click', function(){
				ctrl.clickEffect();
			});

			elem.bind('mouseenter', function(){
				ctrl.hoverEffect();
			});
		}
	};
}]);