'use strict';

angular.module('poi360.views.poi-flashcards', ['ngRoute'])

.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('poi-flashcards', {
        url: "/poi-flashcards",
        templateUrl: 'scripts/app/views/poi-flashcards/poi-flashcards.html',
	    controller: 'TeReoTikangaCtrl',
		loadItems: 0
    });
}])

.controller('TeReoTikangaCtrl', ['$scope', 'animationSettings', '$animate', 'appHeaderFactory', function($scope, animationSettings, $animate, appHeaderFactory) {

  	animIn();

  	function animIn(){
		var $page = $('.poi-flashcards');
		var splitText = new SplitText('.poi-flashcards__intro-p', {type: "words, chars"});
		var revealTl = new TimelineMax();

		revealTl.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease})
			.from($('.poi-flashcards__intro-h1'), animationSettings.intro.title.speed, {opacity: 0, y: animationSettings.intro.title.y, ease: animationSettings.intro.title.ease})
			.staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed}, animationSettings.intro.splitText.charStagger, animationSettings.intro.splitText.offset, animComplete);
	}

	function animComplete(){
		appHeaderFactory.showHeader(true);
		animTiles()
	}

	function animTiles(){
		var cards = $('.poi-flashcards__card-wrapper');

		var revealTl = new TimelineMax();
		revealTl.set(cards, {y: animationSettings.tiles.y});	

		revealTl.staggerTo(cards, animationSettings.tiles.speed, {opacity: 1, y: 0, ease: animationSettings.tiles.ease}, animationSettings.tiles.stagger)
			.to($('.extended-footer'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease});
	}

	$scope.showIntro = false;
	$scope.cards = [
		{
			english: 'Poi Awe',
			teReo: '<p>Poi with tassles, used for ceremonial purposes</p>',
			image: 'POI_AWE_2.jpg',
			flipped: false,
			logo: true
		},
		{
			english: 'Poi RaupŌ',
			teReo: '<p>Poi made from bulrush plant, used in the early 1900s for performance</p>',
			image: 'poi-me004097_float.jpg',
			flipped: false,
			logo: true
		},
		{
			english: 'Poi TĀniko',
			teReo: '<p>Patterned poi with a woven exterior, seen mostly in the artistic domain</p>',
			image: 'Poi_Taniko.jpg',
			flipped: false,
			logo: true
		},
		{
			english: 'Poi Waeroa',
			teReo: '<p>Poi with a long handle, used in performance</p>',
			image: 'poi_waeroa.jpg',
			flipped: false,
			logo: false
		},
		{
			english: 'Poi Takirua',
			teReo: '<p>Double short poi, used in performance</p>',
			image: 'poi_takirua.jpg',
			flipped: false,
			logo: false
		},
		{
			english: 'Poi Kokau',
			teReo: '<p>Poi with a short handle, used in performance</p>',
			image: 'poi_kokau.jpg',
			flipped: false,
			logo: false
		}
	];

	$scope.toggleCard = function($event, card){
		if(card.flipped === true){
			card.flipped = false;
		}else{
			card.flipped = true;
		}

		animFlip($event, card.flipped);
	}

	$scope.hoverCard = function($event, action){
		var enter = action == 'enter' ? true : false;
		animHover($event, enter);	
	}

	function animFlip($event, flipped){
		var $card = $event.currentTarget;
		var $cardFront = $($card).find('.poi-flashcards__card-front');
		var $cardBack = $($card).find('.poi-flashcards__card-back');		
		var flipSpeed = animationSettings.speed.medium;

		var tl = new TimelineMax();
		// var splitText = new SplitText($($card).find('.poi-flashcards__card-te-reo--back'), {type: "words, chars"});

		if(flipped === true){
			tl.to($cardFront, flipSpeed, {css: {rotationY: "-180"}, ease: animationSettings.ease.easeOut})
			.to($cardBack, flipSpeed, {css: {rotationY: "0"}, ease: animationSettings.ease.easeOut}, "-="+flipSpeed);
			// .staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed}, animationSettings.intro.splitText.charStagger);
		}else{
			tl.to($cardFront, flipSpeed, {css: {rotationY: "0"}, ease: animationSettings.ease.easeOut})
			.to($cardBack, flipSpeed, {css: {rotationY: "180"}, ease: animationSettings.ease.easeOut}, "-="+flipSpeed);
		}
	}

	function animHover($event, enter){
		var $card = $event.currentTarget;
		var tl = new TimelineMax();
		var scale = 1;

		if(enter === true){
			scale = 1.02;
		}

		tl.to($card, animationSettings.speed.fast * 0.5, {scale: scale, ease: animationSettings.ease.easeOut});
	}
}]);
// .directive('flipCard', [function(){
// 	return {
// 		scope: {
// 			card: "="
// 		},
// 		templateUrl: 'scripts/app/views/poi-flashcards/flip-card.html',
// 		link: function(scope, element, attrs, API){
// 			elem.bind('click', function() {

// 			}
// 		}
// 	};
// }])