'use strict';

angular.module('poi360.components.page-background', [])

.directive('appBg', ['$location', function($location){
	return {
		scope: {
			bgClass: "@"
		},
		template: '<div class="page-background {{bgClass}}"></div>',
		link: function(scope, element, attrs){
			
	    }
	};
}]);
