'use strict';

angular.module('poi360.views.poi-performance', [])

.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('poi-performance', {
        url: "/poi-performance",
        templateUrl: 'scripts/app/views/poi-performance/poi-performance.html',
	    controller: 'PoiPerformanceCtrl',
		loadItems: 0
    });
}])

.controller('PoiPerformanceCtrl', ['$scope', 'animationSettings', '$animate', 'appHeaderFactory', '$http', '$timeout', '$location', '$anchorScroll', function($scope, animationSettings, $animate, appHeaderFactory, $http, $timeout, $location, $anchorScroll) {

	animIn();

	function animIn(){
  		var $page = $('.poi-performance');
  		var splitText = new SplitText('.poi-performance__intro-p', {type: "words, chars"});

  		var revealTl = new TimelineMax();
  		revealTl.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease})
  			.to($('.poi-performance__intro'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease})
			.from($('.poi-performance__intro-h1'), animationSettings.intro.title.speed, {opacity: 0, y: animationSettings.intro.title.y, ease: animationSettings.intro.title.ease})
			.staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed}, animationSettings.intro.splitText.charStagger, animationSettings.intro.splitText.offset, animComplete);

  	}

  	function animComplete(){
		appHeaderFactory.showHeader(true);
		dataCall();
	}

  	function dataCall(){
  		var revealTl = new TimelineMax();
		revealTl.to($('.video-gallery__loading'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease})

  		$http({
		  method: 'GET',
		  url: '/data/poi-performance.json'
		}).then(function successCallback(response) {
		    $scope.videos = response.data.videos;
			
		    animFooter();
		    
		  }, function errorCallback(response) {
		    // called asynchronously if an error occurs
		    // or server returns response with an error status.
		  });
  	}

  	var animFooter = function(){
		var revealTl = new TimelineMax();

		revealTl.to($('.extended-footer'), animationSettings.fadeIn.speed, {opacity: 1, ease: animationSettings.fadeIn.ease});
	}

}]);