'use strict';

angular.module('poi360.views.explore-aotearoa', ['ngRoute'])

.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('explore-aotearoa', {
        url: "/explore-aotearoa/:story",
        templateUrl: 'scripts/app/views/explore-aotearoa/explore-aotearoa.html',
    	controller: 'ExploreAotearoaCtrl',
    	loadItems: 4,
    	resolve: {
    		pageWait: ['$q', '$timeout', 'animationSettings', function($q, $timeout, animationSettings){
	             var deferred = $q.defer();
	             $timeout(function() {
	                 deferred.resolve();
	             }, animationSettings.loadingPage.stayOpen);
	             return deferred.promise;
	        }],
        	lazyLoad: ['$ocLazyLoad', 'progressBar', function($ocLazyLoad, progressBar) {
		        return $ocLazyLoad.load(['https://cesiumjs.org/releases/1.52/Build/Cesium/Cesium.js']).then(
		        	function(data){
						// RESOLVE
						progressBar.itemComplete();
					}
		        );
		    }],
		  	preloadImages: ['preloader', 'progressBar', function(preloader, progressBar){
				var imageLocations = [
					"images/cloud-26.png",
					"images/cloud-32.png",
					"images/triangle-tile-pattern@2x.png"
				];

				// Preload the images
				return preloader.preloadImages(imageLocations).then(
					function(imageLocations){
						//RESOLVE
						return imageLocations;
					},
					function(imageLocation){
						//REJECT
						return imageLocation;
					},
					function(event){
						// NOTIFY
						progressBar.itemComplete();
					}
				);
	        }]
		},
		onEnter: ["progressBar", function(progressBar){
			var progressComplete = progressBar.complete();
		}],
		onExit: ['soundFactory', 'mobileDetection', function(soundFactory, mobileDetection){
			if(!mobileDetection.isMobile()){
				soundFactory.stopLoop('background');
				soundFactory.hideVolume();
			}
		}]
    });
}])

.controller('ExploreAotearoaCtrl', ['$scope', '$animate', 'appHeaderFactory', '$stateParams', 'soundFactory', 'animationSettings', function($scope, $animate, appHeaderFactory, $stateParams, soundFactory, animationSettings) {

	if(!$scope.isMobile){
		soundFactory.load('background');
		soundFactory.showVolume();
		soundFactory.playLoop('background');
	}
	
	// $animate.on('leave', $('.loading-screen'), function(element) {
 //    	if(!element.hasClass('ng-animate')){
 //    		animIn();
 //    		if(!$scope.isMobile) soundFactory.playLoop('background');
 //    	}
 //  	});

  	$scope.story = $stateParams.story;

  	function animIn(){
		var $page = $('.explore-aotearoa');

		var revealTl = new TimelineMax();
		revealTl.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease, onComplete: animComplete}, "+="+animationSettings.loadingPage.speedOut);
	}

	var animComplete = function(){
		appHeaderFactory.showHeader(true);
	}

	animIn();
}]);