'use strict';

angular.module('poi360.views.poi-360-experience', [])

.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('poi-360-experience', {
        url: "/poi-360-experience",
        templateUrl: 'scripts/app/views/poi-360-experience/poi-360-experience.html',
    	controller: 'Poi360ExperienceCtrl',
    	loadItems: 1,
	    resolve: {
	    	pageWait: ['$q', '$timeout', 'animationSettings', function($q, $timeout, animationSettings){
	             var deferred = $q.defer();
	             $timeout(function() {
	                 deferred.resolve();
	             }, animationSettings.loadingPage.stayOpen);
	             return deferred.promise;
	        }]
	    },
		onEnter: ["progressBar", function(progressBar){
			var progressComplete = progressBar.complete();	
		}],
    });
}])

.controller('Poi360ExperienceCtrl', ['$scope', '$sce', 'appHeaderFactory', '$animate', 'animationSettings', 'mobileDetection', function($scope, $sce, appHeaderFactory, $animate, animationSettings, mobileDetection){

	$scope.isMobile = mobileDetection.isMobile();
	$scope.videoId = '7gjR60TSn8Q';
	$scope.playerVars = {
	    autoplay: 1,
	    modestbranding: 1,
	    playsinline: 1,
	    rel: 0,
	    showinfo: 0
	};

	// $animate.on('leave', $('.loading-screen'), function(element) {
 //    	if(!element.hasClass('ng-animate')){
 //    		animIn();
 //    	}
 //  	});

	var animIn = function(){
		var $page = $('.poi-360-experience');
		
		var revealTl = new TimelineMax();
		revealTl.from($page, animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease, onComplete: animComplete}, "+="+animationSettings.loadingPage.speedOut);

	}
	
	var animComplete = function(){
		appHeaderFactory.showHeader(true);
	}

	animIn();
}]);