'use strict';

angular.module('poi360.views.around-the-world', ['ngRoute'])

.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('around-the-world', {
        url: "/around-the-world",
        templateUrl: 'scripts/app/views/around-the-world/around-the-world.html',
    	controller: 'AroundTheWorldCtrl'
    });
}])

.controller('AroundTheWorldCtrl', [function() {

}]);