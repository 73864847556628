'use strict';

angular.module('poi360.components.vg-poi-marker-overlay.vg-poi-marker-overlay-directives', [])

.directive('vgPoiMarker', [function(){
	return {
		require: "^videogular",
		template: '<div class="vg-poi-marker-overlay__marker" ng-if="ctrl.poiCuePointParams" ng-style="{top: ctrl.poiCuePointParams.position.top, left: ctrl.poiCuePointParams.position.left}" ng-click="openOverlay()"></div>',
		link: function (scope, elem, attr, API) {
			scope.openOverlay = function(){
				API.pause();
				scope.ctrl.overlayOpen = true;
			}
		}
	};
}])

.directive('vgPoiOverlay', [function(){
	return {
		require: "^videogular",
		templateUrl: "scripts/app/components/vg-poi-marker-overlay/vg-poi-overlay.html",
		link: function(scope, element, attrs){
			// scope.overlayOpen = scope.$parent.overlayOpen;
		}
	};
}]);