'use strict';

angular.module('poi360.components.helpers.sticky-scroll', [])

.directive("stickyScroll", ['$window', function ($window) {
    return function(scope, element, attrs) {
    	var elPos = element.prop('offsetTop');
    	
        angular.element($window).bind("scroll", function() {
             if (this.pageYOffset >= elPos) {
                 scope.scrollChange = true;
             } else {
                 scope.scrollChange = false;
             }
            scope.$apply();
        });
    };
}]);