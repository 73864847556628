'use strict';

angular.module('poi360.views.homepage', ["ui.router"])

.config(['$stateProvider', function($stateProvider) {

	$stateProvider.state('home', {
        url: "/",
        templateUrl: 'scripts/app/views/homepage/homepage.html',
        controller: 'HomepageCtrl',
        loadItems: 2,
        resolve: {
        	lazyLoad: ['$ocLazyLoad', 'progressBar', function($ocLazyLoad, progressBar) {
		        return $ocLazyLoad.load(['scripts/vendor/owl.carousel.min.js']).then(
		        	function(data){
						// RESOLVE
						progressBar.itemComplete();
					}
		        );
		    }],
		  	preloadImages: ['preloader', 'progressBar', 'mobileDetection', function(preloader, progressBar, mobileDetection){
				
		  		var imageLocations = [];

		  		// if(mobileDetection.isMobile()){
		  			imageLocations = [
						"images/backgrounds/background-home.jpg" 
					];
		  	// 	}else{
		  	// 		imageLocations = [
		  	// 			"https://player.vimeo.com/external/173001359.sd.mp4?s=b69c6819b33c168dfb1c757c74bfac1333a7d626&profile_id=165"
					// ];
		  	// 	}

				// Preload the images
				return preloader.preloadImages(imageLocations).then(
					function(imageLocations){
						//RESOLVE
						return imageLocations;
					},
					function(imageLocation){
						//REJECT
						return imageLocation;
					},
					function(event){
						// NOTIFY
						progressBar.itemComplete();
					}
				);
	        }]
		},
		onEnter: ["progressBar", function(progressBar){
			var progressComplete = progressBar.complete();
		}],
		onExit: ['soundFactory', 'mobileDetection', function(soundFactory, mobileDetection){
			if(!mobileDetection.isMobile()){
				soundFactory.stopLoop('background');
				soundFactory.hideVolume();
			}
		}]
    });
}])

.controller('HomepageCtrl', ['$scope', '$animate', 'mobileDetection', '$rootScope', 'appHeaderFactory', 'animationSettings', 'soundFactory', function($scope, $animate, mobileDetection, $rootScope, appHeaderFactory, animationSettings, soundFactory){
	$scope.subTitles = {
		doco: false,
		video: false,
		explore: false
	};

	$scope.bgVideo = "https://player.vimeo.com/external/173001359.sd.mp4?s=b69c6819b33c168dfb1c757c74bfac1333a7d626&profile_id=165";
	$scope.isMobile = mobileDetection.isMobile();
	
	if(!$scope.isMobile){
		soundFactory.load('background');
		soundFactory.showVolume();
		soundFactory.playLoop('background');
	}

  	var animComplete = function(){
		appHeaderFactory.showHeader(true);
	}	
 
	var animIn = function(){
		var $textEl = $('.homepage__anim-text');
		var $quickLinks = $('.homepage__quick-links-item');
		
		var splitText = new SplitText('.homepage__anim-text', {type: "words, chars"});
		
		var pageTl = new TimelineMax();
		var revealTl = new TimelineMax({paused:true});

		revealTl.from($('.homepage__h4'), animationSettings.intro.title.speed, {opacity: 0, y: animationSettings.intro.title.y, delay: 2, ease: animationSettings.intro.title.ease})
				.from($('.homepage__h1'), animationSettings.intro.title.speed, {opacity: 0, y: animationSettings.intro.title.y, ease: animationSettings.intro.title.ease}, animationSettings.intro.title.offset)
				.staggerFrom(splitText.chars, animationSettings.intro.splitText.charSpeed, {opacity: 0, ease: animationSettings.intro.splitText.charSpeed}, animationSettings.intro.splitText.charStagger, animationSettings.intro.splitText.offset)
				.to($('.homepage__quick-links'), animationSettings.intro.title.speed, {opacity: 1, ease: animationSettings.fadeIn.ease, onComplete: animComplete});

				// .staggerFrom($quickLinks, animationSettings.homepageTiles.speed, {opacity: 0, y: animationSettings.homepageTiles.y, ease: animationSettings.homepageTiles.ease}, animationSettings.homepageTiles.stagger)

		if(appHeaderFactory.getHeaderState() === true){
			pageTl.from($('.homepage'), animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease, onComplete: revealSecondVisit(revealTl)}, "+="+animationSettings.loadingPage.speedOut)
		}else{
			pageTl.from($('.homepage'), animationSettings.page.speed, {opacity: 0, ease: animationSettings.page.ease, onComplete: revealFirstVisit(revealTl)}, "+="+animationSettings.loadingPage.speedOut)
		}
	}



	function revealFirstVisit(revealTl){
		revealTl.play();
	}

	function revealSecondVisit(revealTl){
		revealTl.seek(revealTl.totalDuration(), false);
	}
	

	animIn();
	

}]);